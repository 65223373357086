import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchAllSessions,
    addSession,
    updateSessionData,
    removeSession,
    incrementSessionLevel,
    resetSessionLevel
} from '../../../../store/dashboard/sessionsSlice';
import { fetchAllUsers } from '../../../../store/dashboard/usersSlice';
import { fetchAllPrograms } from '../../../../store/dashboard/programsSlice';
import CommonLayout from './common/CommonLayout';
import CommonListLayout from './common/CommonListLayout';
import ListItem from './common/ListItem';
import CreateNewButton from './common/CreateNewButton';
import EditForm from './common/EditForm';
import SessionLevelControl from './common/SessionLevelControl';

const SessionsComponent = () => {
    const dispatch = useDispatch();
    const { sessions, loading: sessionsLoading, error: sessionsError } = useSelector((state) => state.sessions);
    const { users } = useSelector((state) => state.users);
    const { programs } = useSelector((state) => state.programs);
    const [selectedSession, setSelectedSession] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [formData, setFormData] = useState({ user_id: '', program_id: '' });

    const refreshData = useCallback(() => {
        dispatch(fetchAllSessions());
        dispatch(fetchAllUsers());
        dispatch(fetchAllPrograms());
    }, [dispatch]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const handleSaveSession = async () => {
        try {
            const result = await dispatch(addSession(formData)).unwrap();
            console.log("Session created:", result);
            setIsCreating(false);
            setSelectedSession(result);
            // Обновляем список сессий после создания новой
            dispatch(fetchAllSessions());
        } catch (error) {
            console.error('Failed to save session:', error);
            alert(`Failed to save session: ${error.message}`);
        }
    };

    useEffect(() => {
        if (selectedSession) {
            setFormData({
                user_id: selectedSession.user_id || '',
                program_id: selectedSession.program_id || '',
            });
        } else {
            setFormData({ user_id: '', program_id: '' });
        }
    }, [selectedSession]);

    const handleSelectSession = (session) => {
        setSelectedSession(session);
        setIsCreating(false);
    };

    const handleCreateNewSession = () => {
        setSelectedSession(null);
        setIsCreating(true);
        setFormData({ user_id: '', program_id: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleUpdateSession = async () => {
        try {
            const result = await dispatch(updateSessionData({ sessionId: selectedSession.id, updateData: formData })).unwrap();
            console.log("Session updated:", result);
            setSelectedSession(result);
        } catch (error) {
            console.error('Failed to update session:', error);
            alert(`Failed to update session: ${error.message}`);
        }
    };

    const handleDeleteSession = async (sessionId) => {
        if (window.confirm('Are you sure you want to delete this session?')) {
            try {
                await dispatch(removeSession(sessionId)).unwrap();
                setSelectedSession(null);
            } catch (error) {
                console.error('Failed to delete session:', error);
                alert(`Failed to delete session: ${error.message}`);
            }
        }
    };

    const handleIncrementLevel = async (sessionId) => {
        try {
            const result = await dispatch(incrementSessionLevel(sessionId)).unwrap();
            setSelectedSession(prev => ({ ...prev, current_level: result }));
        } catch (error) {
            console.error('Failed to increment level:', error);
            alert(`Failed to increment level: ${error.message}`);
        }
    };

    const handleResetLevel = async (sessionId) => {
        try {
            const result = await dispatch(resetSessionLevel(sessionId)).unwrap();
            setSelectedSession(prev => ({ ...prev, current_level: result }));
        } catch (error) {
            console.error('Failed to reset level:', error);
            alert(`Failed to reset level: ${error.message}`);
        }
    };

    if (sessionsLoading) {
        return <div>Loading sessions...</div>;
    }

    if (sessionsError) {
        return <div>Error: {sessionsError}</div>;
    }

    const sortedSessions = [...sessions].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const sessionList = (
        <CommonListLayout
            createNewButton={
                <CreateNewButton onClick={handleCreateNewSession} label="+ Create New Session" />
            }
        >
            {sortedSessions.map(session => {
                const user = users.find(u => u.id === session.user_id);
                const program = programs.find(p => p.id === session.program_id);
                return (
                    <ListItem
                        key={session.id}
                        title="Session"
                        descriptions={[
                            new Date(session.created_at).toLocaleString(),
                            user ? user.username : 'Unknown User',
                            program ? program.title : 'Unknown Program',
                            `Current Level: ${session.current_level ? session.current_level.index : 'N/A'}`,
                            `ID: ${session.id}`
                        ]}
                        onDelete={() => handleDeleteSession(session.id)}
                        onClick={() => handleSelectSession(session)}
                    />
                );
            })}
        </CommonListLayout>
    );

    const renderDetails = (label, details) => {
        if (!details) return null;
        return (
            <div>
                <h4>{label}</h4>
                {Object.entries(details).map(([key, value]) => (
                    <div key={key}>
                        <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value) : value}
                    </div>
                ))}
            </div>
        );
    };

    const sessionForm = selectedSession || isCreating ? (
        <EditForm
            title={isCreating ? 'Create New Session' : 'Session Details'}
            fields={[
                {
                    label: 'User',
                    type: 'select',
                    name: 'user_id',
                    value: formData.user_id,
                    onChange: handleInputChange,
                    options: users.map(user => ({ value: user.id, label: user.username }))
                },
                {
                    label: 'Program',
                    type: 'select',
                    name: 'program_id',
                    value: formData.program_id,
                    onChange: handleInputChange,
                    options: programs.map(program => ({ value: program.id, label: program.title }))
                },
                ...(selectedSession ? [
                    {
                        label: 'Session Details',
                        type: 'custom',
                        name: 'session_details',
                        render: () => (
                            <>
                                <SessionLevelControl
                                    currentLevel={selectedSession.current_level}
                                    onIncrementLevel={() => handleIncrementLevel(selectedSession.id)}
                                    onResetLevel={() => handleResetLevel(selectedSession.id)}
                                />
                                {renderDetails('Session', {
                                    ...selectedSession,
                                    created_at: new Date(selectedSession.created_at).toLocaleString(),
                                })}
                                {renderDetails('User Details', users.find(u => u.id === selectedSession.user_id))}
                                {renderDetails('Program Details', programs.find(p => p.id === selectedSession.program_id))}
                            </>
                        )
                    }
                ] : [])
            ]}
            onChange={handleInputChange}
            onSave={isCreating ? handleSaveSession : handleUpdateSession}
            onCancel={() => { setSelectedSession(null); setIsCreating(false); }}
            onDelete={!isCreating ? () => handleDeleteSession(selectedSession.id) : undefined}
            readOnly={false}
        />
    ) : (
        <div className="noSessionSelected">Select a session or create a new one</div>
    );

    return (
        <CommonLayout
            title="Sessions"
            leftPanel={sessionList}
            rightPanel={sessionForm}
        />
    );
};

export default SessionsComponent;