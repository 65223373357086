import React, { useState, useRef, useEffect } from 'react';
import { X } from 'lucide-react';
import './SettingsWindow.css';

const SettingsWindow = ({ isVisible, onClose, userData, sessionData, programData }) => {
    const [position, setPosition] = useState({ x: 20, y: 20 });
    const [isDragging, setIsDragging] = useState(false);
    const dragRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - dragRef.current.offsetWidth / 2,
                y: e.clientY - dragRef.current.offsetHeight / 2,
            });
        }
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    if (!isVisible) return null;

    const renderSection = (title, data, fields) => (
        <>
            <h3>{title}</h3>
            {data ? (
                fields.map(field => (
                    <p key={field.key}>{field.label}: {field.getValue(data) ?? 'Not available'}</p>
                ))
            ) : (
                <p>Data not available</p>
            )}
        </>
    );

    return (
        <div className="settings-window" style={{ left: `${position.x}px`, top: `${position.y}px` }}>
            <div className="settings-window-header" ref={dragRef} onMouseDown={handleMouseDown}>
                <span className="settings-window-title">Settings</span>
                <button onClick={onClose} className="settings-window-close" title="Close">
                    <X size={16} />
                </button>
            </div>
            <div className="settings-window-content">
                {renderSection('User Information', userData, [
                    { key: 'id', label: 'User ID', getValue: (data) => data.id },
                    { key: 'username', label: 'Username', getValue: (data) => data.username },
                    { key: 'role', label: 'Role', getValue: (data) => data.role }
                ])}

                {renderSection('Session Information', sessionData, [
                    { key: 'id', label: 'Session ID', getValue: (data) => data.id },
                    { key: 'programId', label: 'Selected Program ID', getValue: (data) => data.programId }
                ])}

                {renderSection('Program Information', programData, [
                    { key: 'id', label: 'Program ID', getValue: (data) => data.id },
                    { key: 'title', label: 'Program Title', getValue: (data) => data.title },
                    { key: 'flowUrl', label: 'Flow URL', getValue: (data) => data.flow_url }
                ])}
            </div>
            <div className="settings-window-resize-handle" onMouseDown={(e) => handleMouseDown(e, 'resize')} />
        </div>
    );
};

export default SettingsWindow;