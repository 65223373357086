import React from 'react';
import { Handle, Position } from 'reactflow';
import './BaseNode.css';

const BaseNode = ({ id, data }) => {
  const {
    title,
    inputs = [],
    outputs = [],
    fields = [],
    onFieldChange,
    onButtonClick,
    onDelete,
  } = data;

  return (
    <div className="base-node">
      <div className="node-header">
        {title}
        <button className="delete-node" onClick={() => onDelete(id)}>×</button>
      </div>
      <div className="node-content">
        {inputs.map((input, index) => (
          <div key={`input-${index}`} className="node-io input">
            <Handle
              type="target"
              position={Position.Left}
              id={`input-${input.id}`}
              style={{ background: input.color || '#555' }}
            />
            <span className="io-label">{input.label}</span>
          </div>
        ))}
        
        {fields.map((field, index) => {
          switch (field.type) {
            case 'input':
              return (
                <div key={`field-${index}`} className="node-field">
                  <label>{field.label}</label>
                  <input
                    type="text"
                    value={field.value}
                    onChange={(e) => onFieldChange(data.id, field.id, e.target.value)}
                  />
                </div>
              );
            case 'select':
              return (
                <div key={`field-${index}`} className="node-field">
                  <label>{field.label}</label>
                  <select
                    value={field.value}
                    onChange={(e) => onFieldChange(data.id, field.id, e.target.value)}
                  >
                    <option value="">Select...</option>
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              );
            case 'button':
              return (
                <button key={`button-${index}`} className="node-button" onClick={() => onButtonClick(data.id, field.id)}>
                  {field.label}
                </button>
              );
            default:
              return null;
          }
        })}
        
        {outputs.map((output, index) => (
          <div key={`output-${index}`} className="node-io output">
            <span className="io-label">{output.label}</span>
            <Handle
              type="source"
              position={Position.Right}
              id={`output-${output.id}`}
              style={{ background: output.color || '#555' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BaseNode;