import React, { useCallback, useState } from 'react';
import BotMessageButton from '../commonTS/BotMessageButton';

const BrainstormActivity = ({ data, onClick, isOpen }) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = useCallback(() => {
        if (!isOpen && !isClicked) {
            console.log("BrainstormActivity: handleClick called");
            setIsClicked(true);
            onClick();
            // Reset the clicked state after a short delay
            setTimeout(() => setIsClicked(false), 1000);
        }
    }, [onClick, isOpen, isClicked]);

    return (
        <BotMessageButton
            onClick={handleClick}
            isOpen={isOpen}
            title="Brainstorm"
            subtitle={data.title}
            icon="brain"
        />
    );
};

export default React.memo(BrainstormActivity);