import { fetchJson, apiRequest } from '../utils/request';
import { BASE_URL } from '../constants';

export const sendToGpt = async (text, messages, instructions, maxTokens, sessionId) => {
    console.warn('sendToGpt is deprecated. Use sendToFlow instead.');
    const response = await fetchJson(`${BASE_URL}/chat/send_to_gpt`, {
        method: 'POST',
        body: JSON.stringify({
            input_text: text,
            history: messages,
            instructions,
            maxTokens,
            session_id: sessionId
        }),
    });

    return {
        response: response.response,
        responseTime: response.responseTime
    };
};

export const sendToFlow = async (text, flowUrl, tweaks = null) => {
    try {
        const cleanFlowUrl = flowUrl.endsWith('/') ? flowUrl.slice(0, -1) : flowUrl;

        const urlParts = cleanFlowUrl.split('/').filter(Boolean);
        const flowId = urlParts[urlParts.length - 1];
        if (!flowId) {
            throw new Error('Invalid flowId: flowId is empty');
        }

        const requestBody = {
            text,
            flowId
        };

        if (tweaks && Object.keys(tweaks).length > 0) {
            requestBody.tweaks = tweaks;
        }

        console.log('Sending to flow:', requestBody);

        const response = await apiRequest(`${BASE_URL}/chat/send_to_flow`, {
            method: 'POST',
            data: requestBody,
        });

        console.log('Response from flow:', response);
        return {
            response: response.content,
            responseTime: 0
        };
    } catch (error) {
        console.error('Error in sendToFlow:', error);
        throw error;
    }
};

export const clearFlowHistory = async (flowUrl) => {
    try {
        const flowId = flowUrl.split('/').pop();

        console.log('Clear flow history for:', { flowId });
        const response = await apiRequest(`${BASE_URL}/chat/clear_history`, {
            method: 'POST',
            data: {
                flowId
            },
        });
        console.log('Response from flow:', response);
        return {
            response: response.completion,
            responseTime: 0
        };
    } catch (error) {
        console.error('Error in sendToFlow:', error);
        throw error;
    }
};
