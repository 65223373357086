import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ClientPageTS from './components/pages/TrainingSpace/ClientPageTS';
import UnauthorizedPage from './components/pages/Unauthorized/UnauthorizedPage';
import LoginPage from './components/pages/Login/LoginPage';
import Dashboard from './components/pages/Dashboard/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import './assets/fonts/fonts.css';

import { Provider } from 'react-redux';
import { dashboardStore } from './store/dashboard/storeDashboard';
import { store } from './store/store';
import { DragDropContext } from 'react-beautiful-dnd';

function App() {
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    document.dispatchEvent(new CustomEvent('onDragEnd', { detail: result }));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Router>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/unauthorized' element={<UnauthorizedPage />} />
          <Route
            path='/clientTS/:sessionId'
            element={
              <PrivateRoute roles={['admin', 'user']}>
                <Provider store={store}>
                  <ClientPageTS />
                </Provider>
              </PrivateRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <PrivateRoute roles={['admin', 'user']}>
                <Provider store={dashboardStore}>
                  <Dashboard />
                </Provider>
              </PrivateRoute>
            }
          />
          <Route path='/' element={<LoginPage />} />
        </Routes>
      </Router>
    </DragDropContext>
  );
}

export default App;
