import React from 'react';

const NodeButtons = ({ addNode }) => (
  <div className="node-buttons">
    <button onClick={() => addNode('base')}>Add Base Node</button>
    <button onClick={() => addNode('session')}>Add Session Node</button>
    <button onClick={() => addNode('user')}>Add User Node</button>
    <button onClick={() => addNode('program')}>Add Program Node</button>
    <button onClick={() => addNode('activity')}>Add Activity Node</button>
    <button onClick={() => addNode('level')}>Add Level Node</button>
  </div>
);

export default NodeButtons;