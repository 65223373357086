import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllUsers, addUser, updateUserData, removeUser } from '../../../../store/dashboard/usersSlice';
import CommonLayout from './common/CommonLayout';
import CommonListLayout from './common/CommonListLayout';
import ListItem from './common/ListItem';
import CreateNewButton from './common/CreateNewButton';
import EditForm from './common/EditForm';

const UsersComponent = () => {
    const dispatch = useDispatch();
    const { users, loading, error } = useSelector((state) => state.users);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [formData, setFormData] = useState({ username: '', email: '', role: 'USER', password: '' });

    const refreshUsers = useCallback(() => {
        dispatch(fetchAllUsers());
    }, [dispatch]);

    useEffect(() => {
        refreshUsers();
    }, [refreshUsers]);

    useEffect(() => {
        if (selectedUser) {
            const currentUser = users.find(user => user.id === selectedUser.id);
            if (currentUser) {
                setFormData({
                    username: currentUser.username || '',
                    email: currentUser.email || '',
                    role: currentUser.role || '',
                    password: ''
                });
            }
        } else {
            setFormData({ username: '', email: '', role: 'USER', password: '' });
        }
    }, [selectedUser, users]);

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setIsCreating(false);
    };

    const handleCreateNewUser = () => {
        setSelectedUser(null);
        setIsCreating(true);
        setFormData({ username: '', email: '', role: 'USER', password: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSaveUser = async () => {
        try {
            if (isCreating) {
                await dispatch(addUser(formData)).unwrap();
            } else {
                await dispatch(updateUserData({ userId: selectedUser.id, updateData: formData })).unwrap();
            }
            refreshUsers();
            setIsCreating(false);
            setSelectedUser(null);
        } catch (error) {
            console.error('Failed to save user:', error);
            alert(`Failed to save user: ${error.message}`);
        }
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await dispatch(removeUser(userId)).unwrap();
                setSelectedUser(null);
                refreshUsers();
            } catch (error) {
                console.error('Failed to delete user:', error);
                alert(`Failed to delete user: ${error.message}`);
            }
        }
    };

    const formatRole = (role) => {
        return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
    };

    if (loading) {
        return <div>Loading users...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const userList = (
        <CommonListLayout
            createNewButton={
                <CreateNewButton onClick={handleCreateNewUser} label="+ Create New User" />
            }
        >
            {users && users.map(user => (
                <ListItem
                    key={user.id}
                    title={user.username}
                    descriptions={[user.email, `${formatRole(user.role)}`]}
                    onDelete={() => handleDeleteUser(user.id)}
                    onClick={() => handleSelectUser(user)}
                />
            ))}
        </CommonListLayout>
    );

    const userForm = selectedUser || isCreating ? (
        <EditForm
            title={isCreating ? 'Create New User' : 'Edit User'}
            fields={[
                { label: 'Username', type: 'text', name: 'username', value: formData.username, onChange: handleInputChange },
                { label: 'Email', type: 'email', name: 'email', value: formData.email, onChange: handleInputChange },
                ...(isCreating ? [{ label: 'Password', type: 'password', name: 'password', value: formData.password, onChange: handleInputChange }] : []),
                {
                    label: 'Role',
                    type: 'select',
                    name: 'role',
                    value: formData.role,
                    onChange: handleInputChange,
                    options: [
                        { value: 'USER', label: 'User' },
                        { value: 'ADMIN', label: 'Admin' }
                    ]
                }
            ]}
            onSave={handleSaveUser}
            onCancel={() => { setSelectedUser(null); setIsCreating(false); }}
            onDelete={!isCreating ? () => handleDeleteUser(selectedUser.id) : undefined}
            readOnly={false}
        />
    ) : (
        <div className="noUserSelected">Select a user or create a new one</div>
    );

    return (
        <CommonLayout
            title="Users"
            leftPanel={userList}
            rightPanel={userForm}
        />
    );
};

export default UsersComponent;