import BaseNode from './nodeLib/BaseNode';
import SessionNode from './nodeLib/SessionNode';
import UserNode from './nodeLib/UserNode';
import ProgramNode from './nodeLib/ProgramNode';
import ActivityNode from './nodeLib/ActivityNode';
import LevelNode from './nodeLib/LevelNode';

export const nodeTypes = {
  baseNode: BaseNode,
  sessionNode: SessionNode,
  userNode: UserNode,
  programNode: ProgramNode,
  activityNode: ActivityNode,
  levelNode: LevelNode,
};