import React from 'react';
import BaseNode from './BaseNode';

const LevelNode = ({ data }) => {
  return (
    <BaseNode
      data={{
        ...data,
        title: 'Level',
        fields: [
          { id: 'index', type: 'input', label: 'Index', value: data.index },
          { id: 'content', type: 'input', label: 'Content', value: data.content },
          { id: 'descriptionShort', type: 'input', label: 'Description Short', value: data.descriptionShort },
          { id: 'descriptionLong', type: 'input', label: 'Description Long', value: data.descriptionLong },
        ],
      }}
    />
  );
};

export default LevelNode;