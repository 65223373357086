import React from 'react';
import ContextMenu from './ContextMenu';

const RecordingOptionsTS = ({ setRecordingMode, closeOptions, show }) => {
    const handleOptionClick = (mode) => {
        setRecordingMode(mode);
        closeOptions();
    };

    return (
        <ContextMenu show={show} onClose={closeOptions}>
            <div className="context-menu-option" onClick={() => handleOptionClick('hold')}>
                Hold to Record
            </div>
            <div className="context-menu-option" onClick={() => handleOptionClick('listening')}>
                Always Listening
            </div>
        </ContextMenu>
    );
};

export default RecordingOptionsTS;