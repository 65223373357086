import React from 'react';
import BaseNode from './BaseNode';

const UserNode = ({ id, data }) => {
  return (
    <BaseNode
      id={id}
      data={{
        ...data,
        title: 'User',
        outputs: [
          { id: 'userId', label: 'User ID', color: '#ff6b6b' },
        ],
        fields: [
          {
            id: 'selectUser',
            type: 'select',
            label: 'Select User',
            options: data.users.map(user => ({ value: user.email, label: user.username })),
            value: data.selectedUser,
          },
        ],
      }}
    />
  );
};

export default UserNode;