import { apiRequest } from '../utils/request';
import { BASE_URL, DEFAULT_VOICE } from '../constants';

export const getTtsAudio = async (text, voice) => {
    const selectedVoice = voice || DEFAULT_VOICE;

    try {
        const response = await apiRequest(`${BASE_URL}/audio/tts`, {
            method: 'POST',
            data: { voice: selectedVoice, text },
            responseType: 'blob',
        });
        return response;
    } catch (error) {
        console.error('Error in getTtsAudio:', error);
        // Дополнительное логирование для отладки
        if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
        }
        throw error;
    }
};

export const sendAudioToServer = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.webm');

    try {
        const response = await apiRequest(`${BASE_URL}/audio/stt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        });
        return response;
    } catch (error) {
        console.error('Error in sendAudioToServer:', error);
        throw error;
    }
};