import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { BASE_URL } from './services/constants';

const httpLink = createHttpLink({
    uri: `${BASE_URL}/graphql`, // URL вашего GraphQL API
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ addTypename: false }),
});

export default client;
