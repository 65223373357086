import { useCallback } from 'react';
import { useNodesState } from 'reactflow';
import { getNodeData } from '../nodes/nodeData';
import { useDataFetching } from './useDataFetching';

export const useNodes = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const {
    users,
    programs,
    sessions,
    loading,
    error,
    addUser,
    updateUserData,
    removeUser,
    addProgram,
    updateProgramData,
    removeProgram,
    addSession,
    removeSession
  } = useDataFetching();

  const onNodeDataChange = useCallback((nodeId, fieldId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              [fieldId]: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onNodeButtonClick = useCallback((nodeId, buttonId) => {
    console.log(`Button ${buttonId} clicked on node ${nodeId}`);
    // Implement specific actions for button clicks here
  }, []);

  const onDeleteNode = useCallback((nodeId) => {
    console.log(`Deleting node with id: ${nodeId}`);
    setNodes((nds) => {
      const updatedNodes = nds.filter((node) => node.id !== nodeId);
      console.log('Updated nodes after deletion:', updatedNodes);
      return updatedNodes;
    });
  }, [setNodes]);

  const addNode = useCallback((type) => {
    const newNodeId = `${type}-${Date.now()}`;
    const newNode = {
      id: newNodeId,
      type: `${type}Node`,
      position: { x: Math.random() * 300, y: Math.random() * 300 },
      data: {
        ...getNodeData(type, users, programs, onNodeDataChange, onNodeButtonClick),
        users,
        programs,
        onDelete: () => onDeleteNode(newNodeId),
      },
    };

    console.log(`Adding new node:`, newNode);
    setNodes((nds) => [...nds, newNode]);
  }, [setNodes, users, programs, onNodeDataChange, onNodeButtonClick, onDeleteNode]);

  return {
    nodes,
    onNodesChange,
    addNode,
    onNodeDataChange,
    onNodeButtonClick,
    onDeleteNode,
    loading,
    error,
    users,
    programs,
    sessions,
    addUser,
    updateUserData,
    removeUser,
    addProgram,
    updateProgramData,
    removeProgram,
    addSession,
    removeSession
  };
};