import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import './UnityContainer.css';
import { useUnity } from '../../services/unity';
import { unityInitialize, unityUpdateContainerSize, unitySendMessageDPR } from '../../services/unity/UnityHelpers';

const UnityContainer = React.memo(({ onUnityLoad, containerStyle = { width: '180px', height: '180px' }, instanceId }) => {
    const unityContainerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 180, height: 180 });
    const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);
    const [fadeOut, setFadeOut] = useState(false);
    const [overlayVisible, setOverlayVisible] = useState(true);
    const [error, setError] = useState(null);
    const loadingProgressionRef = useRef(0);
    const isLoadedRef = useRef(false);

    const isDevelopment = process.env.NODE_ENV === 'development';

    const { unityProvider, isLoaded, loadingProgression, sendMessage, requestFullscreen } = useUnityContext({
        loaderUrl: isDevelopment ? '/Build/public.loader.js' : '/Build/public.loader.js',
        dataUrl: isDevelopment ? '/Build/public.data' : '/BuildGZ/public.data.gz',
        frameworkUrl: isDevelopment ? '/Build/public.framework.js' : '/BuildGZ/public.framework.js.gz',
        codeUrl: isDevelopment ? '/Build/public.wasm' : '/BuildGZ/public.wasm.gz',
        productName: "TrainingSpaceGPT",
        productVersion: "1.0.0",
        companyName: "TrainingSpace",
        instanceId: instanceId,
        onError: (message) => {
            console.error('Unity error:', message);
            setError(message);
        },
    });

    const { setUnitySendMessage } = useUnity();

    const handleUnityLoad = useCallback(() => {
        if (isLoaded && sendMessage && !isLoadedRef.current) {
            isLoadedRef.current = true;
            setUnitySendMessage(sendMessage, instanceId);
            onUnityLoad({ isLoaded: true, sendMessage });
            console.log(`Unity instance ${instanceId} loaded successfully`);
        }
        requestFullscreen(false);
        setTimeout(() => {
            setFadeOut(true);
            setTimeout(() => {
                setOverlayVisible(false);
            }, 2000);
        }, 2500);
    }, [isLoaded, sendMessage, setUnitySendMessage, onUnityLoad, requestFullscreen, instanceId]);

    useEffect(() => {
        if (isLoaded) {
            handleUnityLoad();
        }
    }, [isLoaded, handleUnityLoad]);

    useEffect(() => {
        const updatePixelRatio = () => {
            setDevicePixelRatio(window.devicePixelRatio);
        };
        window.matchMedia(`screen and (resolution: ${window.devicePixelRatio}dppx)`).addListener(updatePixelRatio);
        return () => {
            window.matchMedia(`screen and (resolution: ${window.devicePixelRatio}dppx)`).removeListener(updatePixelRatio);
        };
    }, []);

    useEffect(() => {
        if (isLoaded && sendMessage) {
            unitySendMessageDPR(isLoaded, sendMessage, devicePixelRatio, instanceId);
        }
    }, [isLoaded, sendMessage, devicePixelRatio, instanceId]);

    useEffect(() => {
        const handleResize = () => {
            if (unityContainerRef.current) {
                const { clientWidth, clientHeight } = unityContainerRef.current;
                setContainerSize({ width: clientWidth, height: clientHeight });
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        loadingProgressionRef.current = loadingProgression;
    }, [loadingProgression]);

    if (error) {
        return <div>Error loading Unity content: {error}</div>;
    }

    return (
        <div className="unity-container" ref={unityContainerRef} style={containerStyle} id={`unity-container-${instanceId}`}>
            <Unity
                className="unity"
                unityProvider={unityProvider}
                style={{
                    width: containerStyle.width,
                    height: containerStyle.height,
                }}
            />
            {overlayVisible && (
                <div className={`loading-overlay ${fadeOut ? 'fade-out' : ''}`}>
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    );
});

export default UnityContainer;
