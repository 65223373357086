import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useDashboardPageRefresh = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const shouldRefresh = sessionStorage.getItem('shouldRefreshDashboard') === 'true';
        if (shouldRefresh) {
            // ������� ���� ���������� �� sessionStorage
            sessionStorage.removeItem('shouldRefreshDashboard');

            // ��������� ��������
            window.location.reload();
        }
    }, [location, navigate]);
};

export default useDashboardPageRefresh;