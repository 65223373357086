import './styles/ListItem.css';

const ListItem = ({ title, descriptions, onDelete, onClick }) => (
    <div className="listItem" onClick={onClick}>
        <div className="itemInfo">
            <span className="itemTitle" title={title}>{truncateText(title, 25)}</span>
            {Array.isArray(descriptions) ? (
                descriptions.map((desc, index) => (
                    <span key={index} className="itemDescription" title={desc}>
                        {truncateText(desc, 40)}
                    </span>
                ))
            ) : (
                <span className="itemDescription" title={descriptions}>
                    {truncateText(descriptions, 40)}
                </span>
            )}
        </div>
        <button className="deleteButton" onClick={(e) => { e.stopPropagation(); onDelete(); }}>
            ✕
        </button>
    </div>
);

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
};

export default ListItem;
