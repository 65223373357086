import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommonLayout from './common/CommonLayout';
import CommonListLayout from './common/CommonListLayout';
import ListItem from './common/ListItem';
import CreateNewButton from './common/CreateNewButton';
import EditForm from './common/EditForm';
import {
  addFlow,
  fetchAllFlows,
  removeFlow,
  updateFlowData,
} from '../../../../store/dashboard/flowsSlice';

const initialFlow = Object.freeze({
  title: '',
  description: '',
  provider: 'anthropic',
  model: 'llama3-70b-8192 $0.59 / $0.79',
  temperature: 0,
  system_prompt: '',
  author_id: '',
  external_flow_id: '',
});

const FlowsComponent = () => {
  const dispatch = useDispatch();
  const {
    flows,
    loading: flowsLoading,
    error: flowsError,
  } = useSelector((state) => state.flows);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [formData, setFormData] = useState({ ...initialFlow });

  const refreshData = useCallback(() => {
    dispatch(fetchAllFlows());
  }, [dispatch]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    if (selectedFlow) {
      setFormData({
        ...initialFlow,
        ...selectedFlow,
      });
    } else {
      setFormData({ ...initialFlow });
    }
  }, [selectedFlow]);

  const handleSelectFlow = (flow) => {
    setSelectedFlow(flow);
    setIsCreating(false);
  };

  const handleCreateNewFlow = () => {
    setSelectedFlow(null);
    setIsCreating(true);
    setFormData({ ...initialFlow });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveFlow = async () => {
    try {
      if (isCreating) {
        await dispatch(
          addFlow({ ...formData, temperature: Number(formData.temperature) }),
        ).unwrap();
      } else {
        await dispatch(
          updateFlowData({
            flowId: selectedFlow.id,
            flowData: {
              ...formData,
              temperature: Number(formData.temperature),
            },
          }),
        ).unwrap();
      }
      refreshData();
      setIsCreating(false);
    } catch (error) {
      console.error('Failed to save flow:', error);
      alert(`Failed to save flow: ${error.message}`);
    }
  };

  const handleDeleteFlow = async (flowId) => {
    if (window.confirm('Are you sure you want to delete this flow?')) {
      try {
        await dispatch(removeFlow(flowId)).unwrap();
        setSelectedFlow(null);
        refreshData();
      } catch (error) {
        console.error('Failed to delete flow:', error);
        alert(`Failed to delete flow: ${error.message}`);
      }
    }
  };

  if (flowsLoading) {
    return <div>Loading...</div>;
  }

  if (flowsError) {
    return <div>Error: {flowsError}</div>;
  }

  const flowList = (
    <CommonListLayout
      createNewButton={
        <CreateNewButton
          onClick={handleCreateNewFlow}
          label='+ Create New Flow'
        />
      }
    >
      {flows.map((flow) => {
        return (
          <ListItem
            key={flow.id}
            title={flow.title}
            descriptions={[
              `Description: ${flow.description}`,
              `Provider: ${flow.provider}`,
            ]}
            onDelete={() => handleDeleteFlow(flow.id)}
            onClick={() => handleSelectFlow(flow)}
          />
        );
      })}
    </CommonListLayout>
  );

  const flowForm =
    selectedFlow || isCreating ? (
      <EditForm
        title={isCreating ? 'Create New Flow' : 'Flow Details'}
        fields={[
          {
            label: 'Title',
            type: 'text',
            name: 'title',
            value: formData.title,
            onChange: handleInputChange,
          },
          {
            label: 'Description',
            type: 'text',
            name: 'description',
            value: formData.description,
            onChange: handleInputChange,
          },
          {
            label: 'Provider',
            type: 'text',
            name: 'provider',
            value: formData.provider,
            onChange: handleInputChange,
          },
          {
            label: 'Model',
            type: 'text',
            name: 'model',
            value: formData.model,
            onChange: handleInputChange,
          },
          {
            label: 'Temperature',
            type: 'number',
            name: 'temperature',
            value: formData.temperature,
            onChange: handleInputChange,
          },
          {
            label: 'System prompt',
            type: 'textarea',
            name: 'system_prompt',
            value: formData.system_prompt,
            onChange: handleInputChange,
          },
          {
            label: 'External flow id',
            type: 'text',
            name: 'external_flow_id',
            value: formData.external_flow_id,
            onChange: handleInputChange,
          },
        ]}
        onSave={handleSaveFlow}
        onCancel={() => {
          setSelectedFlow(null);
          setIsCreating(false);
        }}
        onDelete={
          !isCreating ? () => handleDeleteFlow(selectedFlow.id) : undefined
        }
      />
    ) : (
      <div className='noFlowSelected'>Select a flow or create a new one</div>
    );

  return (
    <CommonLayout title='Flows' leftPanel={flowList} rightPanel={flowForm} />
  );
};

export default FlowsComponent;
