import React from 'react';
import './styles/SessionLevelControl.css';

const SessionLevelControl = ({ currentLevel, onIncrementLevel, onResetLevel }) => {
    return (
        <div className="sessionLevelControl">
            <h4>Current Level: {currentLevel ? currentLevel.index : 'N/A'}</h4>
            {currentLevel && (
                <>
                    <p>Content: {currentLevel.content}</p>
                    <p>Short Description: {currentLevel.description_short}</p>
                    <p>Activity Type: {currentLevel.activity_type}</p>
                </>
            )}
            <div className="levelButtons">
                <button onClick={onIncrementLevel} className="incrementButton">
                    Increment Level
                </button>
                <button onClick={onResetLevel} className="resetButton">
                    Reset Level
                </button>
            </div>
        </div>
    );
};

export default SessionLevelControl;