import './styles/ProgramLevels.css';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

const ProgramLevels = ({ levels, onRemoveLevel }) => {
  const draggable_levels = levels
    .toSorted((a, b) => a.index - b.index)
    .map((level, index) => {
      return (
        <Draggable draggableId={level.id} key={level.id} index={index}>
          {(provided) => (
            <div
              className='levelItem'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <h4>{level.title}</h4>
              <p>Index: {index + 1}</p>
              <p>Content: {level.content}</p>
              <p>Short Description: {level.description_short}</p>
              <p>Activity Type: {level.activity_type}</p>
              <button
                onClick={() => onRemoveLevel(level.id)}
                className='removeButton'
              >
                Remove Level
              </button>
            </div>
          )}
        </Draggable>
      );
    });

  return (
    <Droppable droppableId='levels-list'>
      {(provided) => (
        <div
          className='programLevels'
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {draggable_levels}
        </div>
      )}
    </Droppable>
  );
};

ProgramLevels.propTypes = {
  levels: PropTypes.array.isRequired,
  onRemoveLevel: PropTypes.func.isRequired,
};

export default ProgramLevels;
