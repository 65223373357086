import { getTtsAudio } from '../api/audio';

export const createTtsAudio = async (gptResponse, voice) => {
    try {
        const audioBlob = await getTtsAudio(gptResponse, voice);
        if (audioBlob instanceof Blob) {
            const audioUrl = URL.createObjectURL(audioBlob);
            const audioDuration = await getAudioDuration(audioUrl);
            return { audioUrl, audioDuration };
        } else {
            console.error('Invalid audio data received:', audioBlob);
            throw new Error('Invalid audio data');
        }
    } catch (error) {
        console.error('Error creating TTS audio:', error);
        throw error;
    }
};

const getAudioDuration = (url) => {
    return new Promise((resolve, reject) => {
        const audio = new Audio(url);
        audio.onloadedmetadata = () => {
            resolve(audio.duration);
        };
        audio.onerror = (error) => {
            reject(error);
        };
    });
};