import { MAX_SILENCE_DURATION } from '../constants';
import { debugService } from '../utils/debugService';
import { getTimeWithMilliseconds } from '../utils/helpers';

class SpeechRecognitionManager {
    constructor(language, onFinalTranscript) {
        this.language = language;
        this.onFinalTranscriptCallback = onFinalTranscript;
        this.recognition = null;
        this.isListening = false;
        this.silenceTimer = null;
        this.audioContext = null;
        this.analyser = null;
    }

    createRecognition() {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            this.recognition = new SpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.maxAlternatives = 1;
            this.recognition.lang = this.language;

            this.recognition.onstart = this.handleStart.bind(this);
            this.recognition.onend = this.handleEnd.bind(this);
            this.recognition.onresult = this.handleResult.bind(this);
            this.recognition.onerror = this.handleError.bind(this);

            debugService.log(`[${getTimeWithMilliseconds()}] Объект распознавания речи создан`);
            return true;
        }
        debugService.log(`[${getTimeWithMilliseconds()}] Распознавание речи не поддерживается в этом браузере`);
        return false;
    }

    async startListening() {
        if (this.isListening) {
            debugService.log(`[${getTimeWithMilliseconds()}] Прослушивание уже активно`);
            return;
        }

        if (!this.recognition) {
            if (!this.createRecognition()) {
                return;
            }
        }

        debugService.log(`[${getTimeWithMilliseconds()}] Начало прослушивания`);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            this.analyser = this.audioContext.createAnalyser();
            const source = this.audioContext.createMediaStreamSource(stream);
            source.connect(this.analyser);

            this.recognition.start();
            this.isListening = true;
        } catch (error) {
            debugService.log(`[${getTimeWithMilliseconds()}] Ошибка при получении доступа к микрофону: ${error.message}`);
            throw error;
        }
    }

    stopListening() {
        if (!this.isListening) {
            debugService.log(`[${getTimeWithMilliseconds()}] Прослушивание уже остановлено`);
            return;
        }

        debugService.log(`[${getTimeWithMilliseconds()}] Остановка прослушивания`);
        this.recognition.stop();
        this.isListening = false;
        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }
        if (this.silenceTimer) {
            clearTimeout(this.silenceTimer);
        }
    }

    handleStart() {
        debugService.log(`[${getTimeWithMilliseconds()}] Распознавание начато`);
        this.isListening = true;
    }

    handleEnd() {
        debugService.log(`[${getTimeWithMilliseconds()}] Распознавание завершено`);
        this.isListening = false;
    }

    handleResult(event) {
        const result = event.results[event.results.length - 1];
        if (result.isFinal) {
            const transcript = result[0].transcript.trim();
            debugService.log(`[${getTimeWithMilliseconds()}] Финальный транскрипт: ${transcript}`);
            this.onFinalTranscriptCallback(transcript);
        }
    }

    handleError(event) {
        debugService.log(`[${getTimeWithMilliseconds()}] Ошибка распознавания речи: ${event.error}`);
        this.isListening = false;
    }
}

let recognitionManager = null;

export const startWebSpeechRecording = (onFinalTranscript, language) => {
    if (!recognitionManager) {
        recognitionManager = new SpeechRecognitionManager(language, onFinalTranscript);
    } else {
        recognitionManager.language = language;
        recognitionManager.onFinalTranscriptCallback = onFinalTranscript;
    }
    return recognitionManager.startListening();
};

export const stopWebSpeechRecording = () => {
    if (recognitionManager) {
        recognitionManager.stopListening();
    }
};

export const isRecognitionActive = () => {
    return recognitionManager && recognitionManager.isListening;
};