import { useState, useCallback, useEffect } from 'react';

export const useWebcamPosition = (containerRef, webcamSize, margins) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const updatePosition = useCallback(() => {
        if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const maxX = containerRect.width - webcamSize.width - margins.right;
            const maxY = containerRect.height - webcamSize.height - margins.bottom;

            setPosition(prevPosition => ({
                x: Math.min(Math.max(prevPosition.x, margins.left), maxX),
                y: Math.min(Math.max(prevPosition.y, margins.top), maxY)
            }));
        }
    }, [containerRef, webcamSize, margins]);

    const onDrag = useCallback((e, offset) => {
        if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const maxX = containerRect.width - webcamSize.width - margins.right;
            const maxY = containerRect.height - webcamSize.height - margins.bottom;

            setPosition({
                x: Math.min(Math.max(e.clientX - containerRect.left - offset.x, margins.left), maxX),
                y: Math.min(Math.max(e.clientY - containerRect.top - offset.y, margins.top), maxY)
            });
        }
    }, [containerRef, webcamSize, margins]);

    return { position, updatePosition, onDrag };
};