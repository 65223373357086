import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import HeaderTS from '../../TrainingSpace/HeaderTS/HeaderTS';
import TabbarTS from "../../TrainingSpace/TabbarTS/TabbarTS";
import DebugSettingsWindows from './components/DebugSettingsWindows';
import ChatContainer from './components/ChatContainer';
// hooks
import { useInitialization } from './hooks/useInitialization';
import usePageRefresh from './hooks/usePageRefresh';
// style
import '../../../assets/styles/global.css';
import './style/ClientPageTS.css';

function ClientPageTS() {
    usePageRefresh();
    const { sessionId } = useParams();
    const location = useLocation();
    const { sessionData: initialSessionData, programData: initialProgramData, userData: initialUserData } = location.state || {};
    const { isLoading, error } = useInitialization(
        sessionId,
        initialSessionData,
      { ...initialProgramData, flow_url: 'brainstorm_assistant_flow' }, // TODO заглушка
        initialUserData
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="containerMainTS">
            <DebugSettingsWindows />
            <div className="containerTS">
                <HeaderTS />
                <ChatContainer />
                <TabbarTS />
            </div>
        </div>
    );
}

export default ClientPageTS;
