import React, { useState, useEffect, useCallback } from 'react';

const MessageQueueManager = ({ setMessages, playAudio }) => {
    const [queue, setQueue] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (queue.length > 0 && !isProcessing) {
            processNextMessage();
        }
    }, [queue, isProcessing]);

    const addToQueue = useCallback((message) => {
        console.log('Adding to queue:', message);

        // Не форматируем JSON в текст, сохраняем исходную структуру
        setQueue(prevQueue => [...prevQueue, { ...message, id: message.id || Date.now() }]);
    }, []);

    const processNextMessage = async () => {
        if (queue.length === 0) return;

        setIsProcessing(true);
        const message = queue[0];
        console.log('Processing message:', message);

        if (message.sender === 'Bot' && message.audioUrl) {
            playAudio(message.audioUrl, message.text);
        }

        setMessages(prevMessages => [...prevMessages, message]);

        setQueue(prevQueue => prevQueue.slice(1));
        setIsProcessing(false);
    };

    return { addToQueue };
};

export default MessageQueueManager;