import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const MindMap = ({ mindmapString }) => {
    const svgRef = useRef(null);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setDimensions({
                    width: Math.max(width - 16, 0),
                    height: Math.max(height - 16, 0)
                });
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!mindmapString || typeof mindmapString !== 'string' || dimensions.width === 0 || dimensions.height === 0) {
            return;
        }

        const { width, height } = dimensions;

        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height);

        svg.selectAll('*').remove();

        // Parse the mindmap string
        const lines = mindmapString.split('\n').filter(line => line.trim() !== '');
        if (lines.length === 0) {
            console.error('No valid data in mindmapString');
            return;
        }

        const root = { name: lines[1].trim(), children: [] };
        const stack = [root];
        let prevIndent = 0;

        for (let i = 2; i < lines.length; i++) {
            const line = lines[i];
            const indent = line.search(/\S/);
            const name = line.trim();

            if (indent > prevIndent) {
                const parent = stack[stack.length - 1];
                const newNode = { name, children: [] };
                parent.children.push(newNode);
                stack.push(newNode);
            } else {
                while (stack.length > 1 && indent <= prevIndent) {
                    stack.pop();
                    prevIndent -= 4; // Assuming 4 spaces per indent level
                }
                const parent = stack[stack.length - 1];
                const newNode = { name, children: [] };
                parent.children.push(newNode);
                stack.push(newNode);
            }
            prevIndent = indent;
        }

        const rootNode = d3.hierarchy(root);

        // Calculate node sizes based on text content
        const calculateNodeSize = (d) => {
            const tempText = svg.append("text")
                .attr("font-family", "'Gilroy', sans-serif")
                .attr("font-size", "21px")
                .text(d.data.name);
            const bbox = tempText.node().getBBox();
            tempText.remove();
            return {
                width: Math.max(100, bbox.width + 25),
                height: Math.max(30, bbox.height + 15)
            };
        };

        rootNode.descendants().forEach(d => {
            const size = calculateNodeSize(d);
            d.width = size.width;
            d.height = size.height;
        });

        const treeLayout = d3.tree()
            .nodeSize([height * 0.15, width * 0.35]);

        const treeData = treeLayout(rootNode);

        const g = svg.append('g');

        // Center alignment
        treeData.descendants().forEach(d => {
            if (d.depth === 1) {
                d.y = d.y * (d.children ? 1 : -1);
            }
        });

        // Calculate bounds of the tree
        const xMin = d3.min(treeData.descendants(), d => d.x);
        const xMax = d3.max(treeData.descendants(), d => d.x);
        const yMin = d3.min(treeData.descendants(), d => d.y);
        const yMax = d3.max(treeData.descendants(), d => d.y);

        const treeWidth = yMax - yMin;
        const treeHeight = xMax - xMin;

        // Calculate scale
        const scaleX = width / (treeWidth + 100);
        const scaleY = height / (treeHeight + 100);
        const scale = Math.min(scaleX, scaleY, 1);

        // Center the diagram in the canvas
        const offsetX = (width - treeWidth * scale) / 2 - yMin * scale;
        const offsetY = (height - treeHeight * scale) / 2 - xMin * scale;

        g.attr('transform', `translate(${offsetX},${offsetY}) scale(${scale})`);

        const link = g.selectAll('.link')
            .data(treeData.links())
            .enter().append('path')
            .attr('class', 'link')
            .attr('d', d3.linkHorizontal()
                .x(d => d.y)
                .y(d => d.x))
            .style('fill', 'none')
            .style('stroke', (d) => getColor(d.target))
            .style('stroke-width', '2px');

        const node = g.selectAll('.node')
            .data(treeData.descendants())
            .enter().append('g')
            .attr('class', 'node')
            .attr('transform', d => `translate(${d.y},${d.x})`);

        node.append('rect')
            .attr('width', d => d.width)
            .attr('height', d => d.height)
            .attr('x', d => -d.width / 2)
            .attr('y', d => -d.height / 2)
            .attr('rx', 4)
            .attr('ry', 4)
            .style('fill', d => getColor(d));

        node.append('text')
            .attr('dy', '0.35em')
            .attr('x', 0)
            .attr('text-anchor', 'middle')
            .text(d => d.data.name)
            .style('font-family', "'Gilroy', sans-serif")
            .style('font-style', 'normal')
            .style('font-weight', '500')
            .style('font-size', '20px')
            .style('line-height', '18px')
            .style('fill', d => d.depth === 0 ? '#050037' : '#FFFFFF')
            .call(wrap, d => d.width - 16);

        function wrap(text, width) {
            text.each(function (d) {
                let text = d3.select(this),
                    words = text.text().split(/\s+/).reverse(),
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1,
                    y = text.attr("y"),
                    dy = parseFloat(text.attr("dy")),
                    tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

                const nodeWidth = typeof width === 'function' ? width(d) : width;

                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > nodeWidth) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                    }
                }
            });
        }

        function getColor(d) {
            const colors = ['#bcd7ff', '#8a64fe', '#fb8500', '#ff9fb8', '#00c164', '#f08181'];
            if (d.depth === 0) return colors[0];
            if (d.depth === 1) {
                // Assign a unique color to each second-level node
                return colors[(d.parent.children.indexOf(d) % (colors.length - 1)) + 1];
            }
            // Children of second-level nodes inherit their parent's color
            return getColor(d.parent);
        }

    }, [mindmapString, dimensions]);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
            <svg ref={svgRef}></svg>
        </div>
    );
};

export default MindMap;