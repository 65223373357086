import { gql } from '@apollo/client';
import client from '../../apollo-client'; // Путь к вашему Apollo Client

// Запрос для получения всех пользователей
const GET_ALL_USERS = gql`
  query GetAllUsers {
    users {
      id
      email
      username
      role
    }
  }
`;

// Мутация для создания пользователя
const CREATE_USER = gql`
    mutation CreateUser($input: CreateUserDto!) {
        createUser(input: $input) {
            id
            email
            username
            role
        }
    }
`;

// Запрос для получения пользователя по ID
const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    user(id: $id) {
      id
      email
      username
      role
    }
  }
`;

// Мутация для обновления пользователя
const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserDto!) {
    updateUser(input: $input) {
      id
      email
      username
      role
    }
  }
`;

// Мутация для удаления пользователя
const DELETE_USER = gql`
  mutation DeleteUser($input: RemoveInputDto!) {
    removeUser(input: $input) {
      id
    }
  }
`;

export const createUser = async (userData) => {
    try {
        const { data } = await client.mutate({
            mutation: CREATE_USER,
            variables: { input: userData }
        });
        console.log("Create user response:", data.createUser);
        return data.createUser;
    } catch (error) {
        console.error("Error in createUser:", error);
        throw error;
    }
};

export const getAllUsers = async () => {
    console.log("Fetching all users");
    try {
        const { data } = await client.query({
            query: GET_ALL_USERS,
            fetchPolicy: 'network-only'  // Добавьте эту строку
        });
        console.log("All users fetched:", data.users);
        return data.users;
    } catch (error) {
        console.error("Error fetching all users:", error);
        throw error;
    }
};

export const getUserById = async (userId) => {
    console.log("Fetching user data for ID:", userId);
    try {
        const { data } = await client.query({
            query: GET_USER_BY_ID,
            variables: { id: userId }
        });
        console.log("Received user data:", data.user);
        if (!data.user) {
            throw new Error('User not found');
        }
        return data.user;
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const updateUser = async (userId, updateData) => {
    console.log("Updating user:", userId, "with data:", updateData);
    try {
        const UPDATE_USER = gql`
            mutation UpdateUser($input: UpdateUserDto!) {
                updateUser(input: $input) {
                    id
                    email
                    username
                    role
                }
            }
        `;

        // Удаляем поля, которые не ожидаются сервером
        const { password, ...validUpdateData } = updateData;

        const { data } = await client.mutate({
            mutation: UPDATE_USER,
            variables: { input: { id: userId, ...validUpdateData } }
        });

        console.log("Update user response:", data.updateUser);
        return data.updateUser;
    } catch (error) {
        console.error("Error in updateUser:", error);
        if (error.graphQLErrors) {
            console.error("GraphQL Errors:", error.graphQLErrors);
        }
        throw error;
    }
};

export const deleteUser = async (userId) => {
    console.log("Deleting user with ID:", userId);
    try {
        const { data } = await client.mutate({
            mutation: DELETE_USER,
            variables: { input: { id: userId } }
        });
        console.log("User deleted:", data.removeUser);
        return data.removeUser;
    } catch (error) {
        console.error("Error in deleteUser:", error.message);
        throw error;
    }
};