import React from 'react';
import BaseNode from './BaseNode';

const ActivityNode = ({ data }) => {
  return (
    <BaseNode
      data={{
        ...data,
        title: 'Activity',
        fields: [
          { id: 'type', type: 'input', label: 'Type', value: data.type },
          { id: 'description', type: 'input', label: 'Description', value: data.description },
        ],
      }}
    />
  );
};

export default ActivityNode;