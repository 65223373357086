import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommonLayout from './common/CommonLayout';
import CommonListLayout from './common/CommonListLayout';
import ListItem from './common/ListItem';
import CreateNewButton from './common/CreateNewButton';
import EditForm from './common/EditForm';
import {
  addActivityTemplate,
  fetchAllActivityTemplates,
  removeActivityTemplate,
  updateActivityTemplate,
} from '../../../../store/dashboard/activityTemplatesSlice';

const initialActivityTemplate = Object.freeze({
  title: '',
  description: '',
});

const ActivityTemplatesComponent = () => {
  const dispatch = useDispatch();
  const {
    activityTemplates,
    loading: activityTemplatesLoading,
    error: activityTemplatesError,
  } = useSelector((state) => state.activityTemplates);

  const [selectedActivityTemplate, setSelectedActivityTemplate] =
    useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [formData, setFormData] = useState({ ...initialActivityTemplate });

  const refreshData = useCallback(() => {
    dispatch(fetchAllActivityTemplates());
  }, [dispatch]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    if (selectedActivityTemplate) {
      setFormData({
        ...initialActivityTemplate,
        ...selectedActivityTemplate,
      });
    } else {
      setFormData({ ...initialActivityTemplate });
    }
  }, [selectedActivityTemplate]);

  const handleSelectActivityTemplate = (activityTemplate) => {
    setSelectedActivityTemplate(activityTemplate);
    setIsCreating(false);
  };

  const handleCreateNewActivityTemplate = () => {
    setSelectedActivityTemplate(null);
    setIsCreating(true);
    setFormData({ ...initialActivityTemplate });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveActivityTemplate = async () => {
    try {
      if (isCreating) {
        await dispatch(addActivityTemplate(formData)).unwrap();
      } else {
        await dispatch(
          updateActivityTemplate({
            id: selectedActivityTemplate.id,
            data: formData,
          }),
        ).unwrap();
      }
      refreshData();
      setIsCreating(false);
    } catch (error) {
      console.error('Failed to save activityTemplate:', error);
      alert(`Failed to save activityTemplate: ${error.message}`);
    }
  };

  const handleDeleteActivityTemplate = async (activityTemplateId) => {
    if (
      window.confirm('Are you sure you want to delete this activityTemplate?')
    ) {
      try {
        await dispatch(removeActivityTemplate(activityTemplateId)).unwrap();
        setSelectedActivityTemplate(null);
        refreshData();
      } catch (error) {
        console.error('Failed to delete activityTemplate:', error);
        alert(`Failed to delete activityTemplate: ${error.message}`);
      }
    }
  };

  if (activityTemplatesLoading) {
    return <div>Loading...</div>;
  }

  if (activityTemplatesError) {
    return <div>Error: {activityTemplatesError}</div>;
  }

  const activityTemplateList = (
    <CommonListLayout
      createNewButton={
        <CreateNewButton
          onClick={handleCreateNewActivityTemplate}
          label='+ Create New ActivityTemplate'
        />
      }
    >
      {activityTemplates.map((activityTemplate) => {
        return (
          <ListItem
            key={activityTemplate.id}
            title={activityTemplate.title}
            descriptions={[activityTemplate.description]}
            onDelete={() => handleDeleteActivityTemplate(activityTemplate.id)}
            onClick={() => handleSelectActivityTemplate(activityTemplate)}
          />
        );
      })}
    </CommonListLayout>
  );

  const activityTemplateForm =
    selectedActivityTemplate || isCreating ? (
      <EditForm
        title={
          isCreating
            ? 'Create New ActivityTemplate'
            : 'ActivityTemplate Details'
        }
        fields={[
          {
            label: 'Title',
            type: 'text',
            name: 'title',
            value: formData.title,
            onChange: handleInputChange,
          },
          {
            label: 'Description',
            type: 'textarea',
            name: 'description',
            value: formData.description,
            onChange: handleInputChange,
          },
        ]}
        onSave={handleSaveActivityTemplate}
        onCancel={() => {
          setSelectedActivityTemplate(null);
          setIsCreating(false);
        }}
        onDelete={
          !isCreating
            ? () => handleDeleteActivityTemplate(selectedActivityTemplate.id)
            : undefined
        }
      />
    ) : (
      <div className='noActivityTemplateSelected'>
        Select an activity template or create a new one
      </div>
    );

  return (
    <CommonLayout
      title='Activity Templates'
      leftPanel={activityTemplateList}
      rightPanel={activityTemplateForm}
    />
  );
};

export default ActivityTemplatesComponent;
