import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {withErrorHandler} from "../../utils/error-handler";
import {
  createActivityTemplate,
  deleteActivityTemplate,
  getAllActivityTemplates, updateActivityTemplates
} from "../../services/api/activity-templates";

export const fetchAllActivityTemplates = createAsyncThunk(
  'activityTemplates/fetchAllActivityTemplates',
  withErrorHandler(() => getAllActivityTemplates()),
);

export const addActivityTemplate = createAsyncThunk(
  'activityTemplates/addActivityTemplate',
  withErrorHandler((data) => createActivityTemplate(data)),
);

export const updateActivityTemplate = createAsyncThunk(
  'activityTemplates/updateActivityTemplate',
  withErrorHandler(({id, data}) => updateActivityTemplates(id, data))
);

export const removeActivityTemplate = createAsyncThunk(
  'activityTemplates/removeActivityTemplate',
  withErrorHandler((id) => deleteActivityTemplate(id))
);

const activityTemplatesSlice = createSlice({
  name: 'activityTemplates',
  initialState: {
    activityTemplates: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllActivityTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllActivityTemplates.fulfilled, (state, action) => {
        state.activityTemplates = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllActivityTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addActivityTemplate.fulfilled, (state, action) => {
        state.activityTemplates.push(action.payload);
      })
      .addCase(updateActivityTemplate.fulfilled, (state, action) => {
        const index = state.activityTemplates.findIndex(({ id }) => id === action.payload.id);
        if (index !== -1) {
          state.activityTemplates[index] = action.payload;
        }
      })
      .addCase(removeActivityTemplate.fulfilled, (state, action) => {
        state.activityTemplates = state.activityTemplates.filter(({ id }) => id !== action.payload);
      });
  },
});

export default activityTemplatesSlice.reducer;
