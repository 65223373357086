import React, { useState, useRef, useEffect } from 'react';
import { X, Trash2, PlayCircle, StopCircle, BarChart2 } from 'lucide-react';
import './DebugWindow.css';
import { debugService } from '../../../services/utils/debugService';
import performanceMonitor from '../performanceMonitor';

const DebugWindow = ({ isVisible, onClose }) => {
    const [logs, setLogs] = useState([]);
    const [position, setPosition] = useState({ x: 20, y: 20 });
    const [isMonitoring, setIsMonitoring] = useState(false);
    const dragRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
        const unsubscribe = debugService.subscribe(setLogs);
        setLogs(debugService.getLogs());
        return () => {
            unsubscribe();
        };
    }, []);

    const handleClearLogs = () => {
        debugService.clearLogs();
    };

    const handleStartMonitoring = () => {
        setIsMonitoring(true);
        performanceMonitor.start('app-performance');
    };

    const handleStopMonitoring = () => {
        setIsMonitoring(false);
        performanceMonitor.end('app-performance');
    };

    const handleLogMemoryUsage = () => {
        performanceMonitor.logMemoryUsage();
    };

    const handleMouseDown = (event) => {
        const startX = event.pageX - position.x;
        const startY = event.pageY - position.y;

        const handleMouseMove = (moveEvent) => {
            setPosition({
                x: moveEvent.pageX - startX,
                y: moveEvent.pageY - startY,
            });
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    if (!isVisible) return null;

    return (
        <div
            className="debug-window"
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
            }}
        >
            <div
                className="debug-window-header"
                ref={dragRef}
                onMouseDown={handleMouseDown}
            >
                <div className="debug-window-title">Debug Console</div>
                <div className="debug-window-controls">
                    <button className="debug-window-button" onClick={handleClearLogs} title="Clear logs">
                        <Trash2 size={16} />
                    </button>
                    <button
                        className="debug-window-button"
                        onClick={isMonitoring ? handleStopMonitoring : handleStartMonitoring}
                        title={isMonitoring ? "Stop monitoring" : "Start monitoring"}
                    >
                        {isMonitoring ? <StopCircle size={16} /> : <PlayCircle size={16} />}
                    </button>
                    <button className="debug-window-button" onClick={handleLogMemoryUsage} title="Log memory usage">
                        <BarChart2 size={16} />
                    </button>
                    <button className="debug-window-button" onClick={onClose} title="Close">
                        <X size={16} />
                    </button>
                </div>
            </div>
            <div className="debug-window-content" ref={contentRef}>
                {logs.map((log, index) => (
                    <div key={index} className="debug-window-log">
                        {log}
                    </div>
                ))}
            </div>
            <div className="debug-window-resize-handle" />
        </div>
    );
};

export default DebugWindow;