import {configureStore} from '@reduxjs/toolkit';
import dashboardDataReducer from './dashboardDataSlice';
import usersReducer from './usersSlice';
import levelsReducer from './levelsSlice';
import programsReducer from './programsSlice';
import sessionsReducer from './sessionsSlice';
import flowsReducer from './flowsSlice';
import activityTemplatesReducer from './activityTemplatesSlice';

export const dashboardStore = configureStore({
  reducer: {
    dashboardData: dashboardDataReducer,
    users: usersReducer,
    programs: programsReducer,
    sessions: sessionsReducer,
    levels: levelsReducer,
    flows: flowsReducer,
    activityTemplates: activityTemplatesReducer
  },
});
