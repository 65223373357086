import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllUsers, createUser, updateUser, deleteUser } from '../../services/api/users';

export const fetchAllUsers = createAsyncThunk(
    'users/fetchAllUsers',
    async (_, { rejectWithValue }) => {
        try {
            return await getAllUsers();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addUser = createAsyncThunk(
    'users/addUser',
    async (userData, { rejectWithValue }) => {
        try {
            const formattedUserData = {
                ...userData,
                role: userData.role.toUpperCase()
            };
            return await createUser(formattedUserData);
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateUserData = createAsyncThunk(
    'users/updateUser',
    async ({ userId, updateData }, { rejectWithValue }) => {
        try {
            const formattedUpdateData = {
                ...updateData,
                role: updateData.role.toUpperCase()
            };
            const updatedUser = await updateUser(userId, formattedUpdateData);
            return { userId, updatedUser };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const removeUser = createAsyncThunk(
    'users/removeUser',
    async (userId, { rejectWithValue }) => {
        try {
            await deleteUser(userId);
            return userId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllUsers.fulfilled, (state, action) => {
                state.users = action.payload;
                state.loading = false;
            })
            .addCase(fetchAllUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(updateUserData.fulfilled, (state, action) => {
                const { userId, updatedUser } = action.payload;
                const index = state.users.findIndex(user => user.id === userId);
                if (index !== -1) {
                    state.users[index] = { ...state.users[index], ...updatedUser };
                }
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                state.users = state.users.filter(user => user.id !== action.payload);
            });
    },
});

export default usersSlice.reducer;