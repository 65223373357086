import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllUsers } from '../../services/api/users';
import { getAllPrograms } from '../../services/api/programs';
import { getAllSessions } from '../../services/api/historySessions';

export const fetchAllData = createAsyncThunk(
    'dashboardData/fetchAllData',
    async (_, { rejectWithValue }) => {
        try {
            const [sessionsResult, programs, users] = await Promise.all([
                getAllSessions().catch(error => {
                    console.error('Error fetching sessions:', error);
                    return [];
                }),
                getAllPrograms(),
                getAllUsers()
            ]);

            return {
                sessions: Array.isArray(sessionsResult) ? sessionsResult : [],
                programs,
                users
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return rejectWithValue(error.message);
        }
    }
);

const dashboardDataSlice = createSlice({
    name: 'dashboardData',
    initialState: {
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllData.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchAllData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default dashboardDataSlice.reducer;