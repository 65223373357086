import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children, roles = [] }) => {
    const token = localStorage.getItem('token');

    //console.log('Token in PrivateRoute:', token);

    if (!token) {
        return <Navigate to="/login" />;
    }

    try {
        const decoded = jwtDecode(token);
        //console.log('Decoded token in PrivateRoute:', decoded);
        //console.log('User role in PrivateRoute:', decoded.role);
        //console.log('Required roles:', roles);

        if (roles.length && !roles.includes(decoded.role)) {
            //console.log('Access denied: role not in required roles');
            return <Navigate to="/unauthorized" />;
        }
        return children;
    } catch (error) {
        //console.error('Error decoding token:', error);
        return <Navigate to="/login" />;
    }
};

export default PrivateRoute;