import { useState, useEffect, useCallback } from 'react';
import { getAllPrograms, createProgram, deleteProgram, updateProgram } from '../../../../../services/api/programs';
import { createSession, getAllSessions, deleteSession } from '../../../../../services/api/historySessions';
import { getAllUsers, createUser, updateUser, deleteUser } from '../../../../../services/api/users';

export const useDataFetching = () => {
  const [users, setUsers] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [usersData, programsData, sessionsData] = await Promise.all([
        getAllUsers(),
        getAllPrograms(),
        getAllSessions()
      ]);
      console.log("Fetched users:", usersData);
      console.log("Fetched programs:", programsData);
      console.log("Fetched sessions:", sessionsData);
      
      setUsers(usersData);
      setPrograms(programsData);
      setSessions(sessionsData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(`Failed to fetch data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const addUser = useCallback(async (userData) => {
    try {
      const newUser = await createUser(userData);
      setUsers(prevUsers => [...prevUsers, newUser]);
      return newUser;
    } catch (err) {
      console.error('Error creating user:', err);
      throw err;
    }
  }, []);

  const updateUserData = useCallback(async (userId, userData) => {
    try {
      const updatedUser = await updateUser(userId, userData);
      setUsers(prevUsers => prevUsers.map(user => user.email === userId ? updatedUser : user));
      return updatedUser;
    } catch (err) {
      console.error('Error updating user:', err);
      throw err;
    }
  }, []);

  const removeUser = useCallback(async (userId) => {
    try {
      await deleteUser(userId);
      setUsers(prevUsers => prevUsers.filter(user => user.email !== userId));
    } catch (err) {
      console.error('Error deleting user:', err);
      throw err;
    }
  }, []);

  const addProgram = useCallback(async (programData) => {
    try {
      const newProgram = await createProgram(programData);
      setPrograms(prevPrograms => [...prevPrograms, newProgram]);
      return newProgram;
    } catch (err) {
      console.error('Error creating program:', err);
      throw err;
    }
  }, []);

  const updateProgramData = useCallback(async (programId, programData) => {
    try {
      const updatedProgram = await updateProgram(programId, programData);
      setPrograms(prevPrograms => prevPrograms.map(program => program.id === programId ? updatedProgram : program));
      return updatedProgram;
    } catch (err) {
      console.error('Error updating program:', err);
      throw err;
    }
  }, []);

  const removeProgram = useCallback(async (programId) => {
    try {
      await deleteProgram(programId);
      setPrograms(prevPrograms => prevPrograms.filter(program => program.id !== programId));
    } catch (err) {
      console.error('Error deleting program:', err);
      throw err;
    }
  }, []);

  const addSession = useCallback(async (userId, programId) => {
    try {
      const newSession = await createSession(userId, programId);
      setSessions(prevSessions => [...prevSessions, newSession]);
      return newSession;
    } catch (err) {
      console.error('Error creating session:', err);
      throw err;
    }
  }, []);

  const removeSession = useCallback(async (sessionId) => {
    try {
      await deleteSession(sessionId);
      setSessions(prevSessions => prevSessions.filter(session => session.id !== sessionId));
    } catch (err) {
      console.error('Error deleting session:', err);
      throw err;
    }
  }, []);

  return {
    users,
    programs,
    sessions,
    loading,
    error,
    addUser,
    updateUserData,
    removeUser,
    addProgram,
    updateProgramData,
    removeProgram,
    addSession,
    removeSession,
    refreshData: fetchData
  };
};