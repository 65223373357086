import FormField from './FormField';
import './styles/EditForm.css';

const EditForm = ({ title, fields, onSave, onCancel, onDelete, readOnly }) => (
    <form className="editForm" onSubmit={(e) => { e.preventDefault(); onSave && onSave(); }}>
        <h3>{title}</h3>
        <div className="formFields">
            {fields.map((field, index) => (
                <FormField
                    key={field.name || `field-${index}`}
                    {...field}
                    readOnly={readOnly}
                />
            ))}
        </div>
        <div className="formButtons">
            {!readOnly && onSave && <button type="submit">Accept</button>}
            {/*{!readOnly && onDelete && <button type="button" onClick={onDelete}>Delete</button>}*/}
            <button type="button" onClick={onCancel}>
                {readOnly ? 'Close' : 'Cancel'}
            </button>
        </div>
    </form>
);

export default EditForm;
