import React, { useCallback } from 'react';
import './style/WebcamUnityTS.css';
import UnityContainer from '../../../components/UnityContainer/UnityContainer';
import { useUnity } from '../../../services/unity';
import BaseWebcamUnityTS from './BaseWebcamUnityTS';

const WebcamUnityTS = React.memo(({ containerRef, isVisible, isHeadphoneActive = false }) => {
    const { setUnitySendMessage, setUnityLoaded } = useUnity();
    const margins = { top: 80, bottom: 130, left: 16, right: 16 };

    const handleUnityLoad = useCallback(({ isLoaded, sendMessage }) => {
        if (isLoaded && sendMessage) {
            setUnitySendMessage(sendMessage, 'main-chat');
            setUnityLoaded(true, 'main-chat');
        }
    }, [setUnitySendMessage, setUnityLoaded]);

    return (
        <BaseWebcamUnityTS
            containerRef={containerRef}
            isVisible={isVisible}
            isHeadphoneActive={isHeadphoneActive}
            unityContainerId="unity-container-main-chat"
            margins={margins}
        >
            <UnityContainer
                onUnityLoad={handleUnityLoad}
                containerStyle={{ width: '100%', height: '100%' }}
                instanceId="main-chat"
            />
        </BaseWebcamUnityTS>
    );
});

export default WebcamUnityTS;