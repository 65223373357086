import React from 'react';
import { Headphones } from 'lucide-react';

const HeadphoneIcon = ({ isActive }) => {
    return (
        <div className="headphone-icon">
            <Headphones color={isActive ? 'white' : 'gray'} size={24} />
            {!isActive && <div className="strikethrough"></div>}
        </div>
    );
};

export default HeadphoneIcon;