import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function useExitHandling() {
    const navigate = useNavigate();
    const userData = useSelector(state => state.session.userData);

    const handleExit = useCallback(() => {
        sessionStorage.setItem('shouldRefreshDashboard', 'true');

        localStorage.setItem('dashboardState', JSON.stringify({
            nickname: userData.username,
            userId: userData.id,
            role: userData.role
        }));

        navigate('/dashboard');
    }, [navigate, userData]);

    return { handleExitClick: handleExit };
}