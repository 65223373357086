import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sessionData: null,
  programData: null,
  userData: null,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionData: (state, action) => {
      state.sessionData = action.payload;
    },
    setProgramData: (state, action) => {
      state.programData = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { setSessionData, setProgramData, setUserData } = sessionSlice.actions;

export default sessionSlice.reducer;