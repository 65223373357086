import { useCallback } from 'react';
import { useEdgesState, addEdge } from 'reactflow';

export const useEdges = (nodes) => {
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback((params) => {
    console.log("Connection params:", params);
    const sourceNode = nodes.find(node => node.id === params.source);
    const targetNode = nodes.find(node => node.id === params.target);
    
    if (sourceNode && targetNode) {
      const sourceOutput = sourceNode.data?.outputs?.find(output => `output-${output.id}` === params.sourceHandle);
      const targetInput = targetNode.data?.inputs?.find(input => `input-${input.id}` === params.targetHandle);
      
      if (sourceOutput && targetInput && sourceOutput.id === targetInput.id) {
        setEdges((eds) => addEdge(params, eds));
      } else {
        console.error("Incompatible connection or missing data:", { sourceOutput, targetInput });
        alert("Incompatible connection. Input and output types must match.");
      }
    } else {
      console.error("Source or target node not found:", { sourceNodeId: params.source, targetNodeId: params.target });
      alert("Error connecting nodes. Please try again.");
    }
  }, [nodes, setEdges]);

  const onEdgeClick = useCallback((event, edge) => {
    const isDelete = window.confirm("Do you want to delete this connection?");
    if (isDelete) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
  }, [setEdges]);

  return { edges, onEdgesChange, onConnect, onEdgeClick };
};