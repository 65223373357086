import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllSessions,
  createSession,
  updateSession,
  deleteSession,
  incrementCurrentLevel,
  resetCurrentLevel,
} from '../../services/api';

export const fetchAllSessions = createAsyncThunk(
  'sessions/fetchAllSessions',
  async (_, { rejectWithValue }) => {
    try {
      return await getAllSessions();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addSession = createAsyncThunk(
  'sessions/addSession',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const newSession = await createSession(
        formData.user_id,
        formData.program_id,
      );
      dispatch(fetchAllSessions());
      return newSession;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateSessionData = createAsyncThunk(
  'sessions/updateSession',
  async ({ sessionId, updateData }, { rejectWithValue }) => {
    try {
      return await updateSession(sessionId, updateData);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeSession = createAsyncThunk(
  'sessions/removeSession',
  async (sessionId, { rejectWithValue }) => {
    try {
      await deleteSession(sessionId);
      return sessionId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const incrementSessionLevel = createAsyncThunk(
  'sessions/incrementLevel',
  async (sessionId, { rejectWithValue }) => {
    try {
      return await incrementCurrentLevel(sessionId);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const resetSessionLevel = createAsyncThunk(
  'sessions/resetLevel',
  async (sessionId, { rejectWithValue }) => {
    try {
      return await resetCurrentLevel(sessionId);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: {
    sessions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllSessions.fulfilled, (state, action) => {
        state.sessions = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addSession.fulfilled, (state, action) => {
        state.sessions.push(action.payload);
      })
      .addCase(updateSessionData.fulfilled, (state, action) => {
        const index = state.sessions.findIndex(
          (session) => session.id === action.payload.id,
        );
        if (index !== -1) {
          state.sessions[index] = action.payload;
        }
      })
      .addCase(removeSession.fulfilled, (state, action) => {
        state.sessions = state.sessions.filter(
          (session) => session.id !== action.payload,
        );
      })
      .addCase(incrementSessionLevel.fulfilled, (state, action) => {
        const index = state.sessions.findIndex(
          (session) => session.id === action.meta.arg,
        );
        if (index !== -1) {
          state.sessions[index].current_level = action.payload;
        }
      })
      .addCase(resetSessionLevel.fulfilled, (state, action) => {
        const index = state.sessions.findIndex(
          (session) => session.id === action.meta.arg,
        );
        if (index !== -1) {
          state.sessions[index].current_level = action.payload;
        }
      });
  },
});

export default sessionsSlice.reducer;
