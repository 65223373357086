import { gql } from '@apollo/client';
import client from "../../apollo-client";


const CREATE_THEORY = gql`
    mutation CreateTheory($input: CreateTheoryActivityInput!) {
      createTheoryActivity(input: $input) {
        id
      }
    }
`

const UPDATE_THEORY = gql`
  mutation UpdateTheory($input: UpdateTheoryActivityInput!) {
    updateTheoryActivity(input: $input) {
      id
    }
  }
`

const CREATE_BRAINSTORM = gql`
  mutation CreateBrainstorm($input: CreateBrainstormActivityInput!) {
    createBrainstormActivity(input: $input) {
      id
    }
  }
`;

const UPDATE_BRAINSTORM = gql`
  mutation UpdateBrainstorm($input: UpdateBrainstormActivityInput!) {
    updateBrainstormActivity(input: $input) {
      id
    }
  }
`

const CREATE_ROLEPLAY = gql`
  mutation CreateRoleplay($input: CreateRoleplayActivityInput!) {
    createRoleplayActivity(input: $input) {
      id
    }
  }
`

const UPDATE_ROLEPLAY = gql`
  mutation UpdateRoleplay($input: UpdateRoleplayActivityInput!) {
    updateRoleplayActivity(input: $input) {
      id
    }
  }
`

const CREATE_DISCUSSION = gql`
  mutation CreateDiscussion($input: CreateDiscussionActivityInput!) {
    createDiscussionActivity(input: $input) {
      id
    }
  }
`

const UPDATE_DISCUSSION = gql`
  mutation UpdateDiscussion($input: UpdateDiscussionActivityInput!) {
    updateDiscussionActivity(input: $input) {
      id
    }
  }
`

const CREATE_VIDEO = gql`
  mutation CreateVideo($input: CreateVideoActivityInput!) {
    createVideoActivity(input: $input) {
      id
    }
  }
`

const UPDATE_VIDEO = gql`
  mutation UpdateVideo($input: UpdateVideoActivityInput!) {
    updateVideoActivity(input: $input) {
      id
    }
  }
`

export const createTheory = async (input) => {
  const { data } = await client.mutate({
    mutation: CREATE_THEORY,
    variables: { input }
  });
  return data.createTheoryActivity.id;
};

export const updateTheory = async (input) => {
  const res = await client.mutate({
    mutation: UPDATE_THEORY,
    variables: { input }
  });
  return res.data.updateTheoryActivity;
};

export const createBrainstorm = async (brainstormData) => {
  const { data } = await client.mutate({
    mutation: CREATE_BRAINSTORM,
    variables: { input: brainstormData }
  });
  return data.createBrainstormActivity.id;
};

export const updateBrainstorm = async (brainstormData) => {
  const { data } = await client.mutate({
    mutation: UPDATE_BRAINSTORM,
    variables: { input: brainstormData }
  });
  return data.updateLevel;
};

export const createRoleplay = async (roleplayData) => {
  const { data } = await client.mutate({
    mutation: CREATE_ROLEPLAY,
    variables: { input: roleplayData }
  });
  return data.createRoleplayActivity.id;
};

export const updateRoleplay = async (input) => {
  const { data } = await client.mutate({
    mutation: UPDATE_ROLEPLAY,
    variables: { input }
  })
  return data.updateRoleplayActivity.id
};

export const createDiscussion = async (input) => {
  const { data } = await client.mutate({
    mutation: CREATE_DISCUSSION,
    variables: { input }
  });
  return data.createDiscussionActivity.id;
};

export const updateDiscussion = async (input) => {
  const res = await client.mutate({
    mutation: UPDATE_DISCUSSION,
    variables: { input }
  });
  return res.data.updateDiscussionActivity;
};

export const createVideo = async (videoData) => {
  const { data } = await client.mutate({
    mutation: CREATE_VIDEO,
    variables: { input: videoData }
  });
  return data.createVideoActivity.id
};

export const updateVideo = async (input) => {
  const { data } = await client.mutate({
    mutation: UPDATE_VIDEO,
    variables: { input }
  })
  return data.updateVideoActivity.id
};
