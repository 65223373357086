import { useState, useCallback } from 'react';

export const useLanguageToggle = (initialLang = 'en-US') => {
    const [language, setLanguage] = useState(initialLang);

    const toggleLanguage = useCallback(() => {
        setLanguage(prevLang => prevLang === 'ru-RU' ? 'en-US' : 'ru-RU');
    }, []);

    const getDisplayLanguage = useCallback(() => {
        return language === 'ru-RU' ? 'RU' : 'ENG';
    }, [language]);

    return { language, toggleLanguage, getDisplayLanguage };
};