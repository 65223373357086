import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDashboardData } from '../hooks/useDashboardData';
import SessionButton from './common/SessionButton';
import './styles/MySessionsComponent.css';

const MySessionsComponent = ({ userId, userData }) => {
    const navigate = useNavigate();
    const {
        userSessions,
        programs,
        loading,
        error,
        removeSession,
        refreshData
    } = useDashboardData(userId);

    useEffect(() => {
        console.log('Component mounted. Refreshing data...');
        refreshData();
    }, [refreshData]);

    const handleEnterSession = (session, program) => {
        console.log('Navigating with userData:', userData);
        sessionStorage.setItem('shouldRefreshClientPageTS', 'true');
        navigate(`/clientTS/${session.id}`, {
            state: {
                sessionData: session,
                programData: program,
                userData: userData
            }
        });
    };

    if (loading) {
        return <div>Loading sessions...</div>;
    }

    if (error) {
        return <div className="errorMessage">Error: {error}</div>;
    }

    return (
        <div className="mySessionsComponent">
            <h2>My Sessions</h2>
            <div className="sessionsGrid">
                {userSessions.map(session => {
                    const program = programs.find(prog => prog.id === session.program_id);
                    return (
                        <SessionButton
                            key={session.id}
                            session={session}
                            program={program}
                            userData={userData}
                            onEnter={handleEnterSession}
                        />
                    );
                })}
            </div>
            {userSessions.length === 0 && (
                <div>
                    <p>No sessions found for this user.</p>
                    <p>User ID: {userId}</p>
                </div>
            )}
        </div>
    );
};

export default MySessionsComponent;