import { sendToFlow } from '../api';
import { createTtsAudio } from '../audio/tts';
import { debugService } from '../utils/debugService';
import { MAIN_VOICE } from '../constants';
import { getTimeWithMilliseconds } from '../utils/helpers';

export const handleMessageProcessing = async (text, flowUrl, voice = MAIN_VOICE) => {
    const startTime = performance.now();

    try {
        debugService.log(`[${getTimeWithMilliseconds()}] Начало обработки сообщения`);

        if (!flowUrl) {
            throw new Error('Flow URL is not defined');
        }

        // Отправка запроса к Flow
        const flowStartTime = performance.now();
        const { response: flowResponse } = await sendToFlow(text, flowUrl);
        const flowEndTime = performance.now();
        debugService.log(`[${getTimeWithMilliseconds()}] Flow обработка: ${(flowEndTime - flowStartTime).toFixed(2)} мс`);

        debugService.log(`[${getTimeWithMilliseconds()}] Ответ Flow получен и обработан`);

        // Попытка парсинга JSON ответа
        let parsedResponse;
        try {
            parsedResponse = JSON.parse(flowResponse);
        } catch (parseError) {
            console.warn('Response is not in JSON format, using raw response');
            parsedResponse = { messages: [{ type: 'text', content: flowResponse }] };
        }

        // Извлечение текстового содержимого для TTS
        const ttsText = parsedResponse.messages
            ? parsedResponse.messages
                .map(msg => msg.type === 'text' ? msg.content : '')
                .filter(content => content && content !== 'undefined')
                .join(' ')
                .trim()
            : parsedResponse.completion || flowResponse;

        // Создание TTS с выбранным голосом
        const ttsStartTime = performance.now();
        const ttsResult = await createTtsAudio(ttsText, voice);
        const ttsEndTime = performance.now();
        debugService.log(`[${getTimeWithMilliseconds()}] TTS обработка: ${(ttsEndTime - ttsStartTime).toFixed(2)} мс`);

        const endTime = performance.now();
        const totalProcessingTime = endTime - startTime;
        debugService.log(`[${getTimeWithMilliseconds()}] Общее время обработки сообщения: ${totalProcessingTime.toFixed(2)} мс`);

        console.log('Audio duration:', ttsResult.audioDuration);

        return {
            audioUrl: ttsResult.audioUrl,
            flowResponse: parsedResponse,
            audioDuration: ttsResult.audioDuration,
            responseTime: totalProcessingTime
        };
    } catch (error) {
        console.error('Error handling message processing:', error);
        debugService.log(`Ошибка при обработке сообщения: ${error.message}`);
        throw error;
    }
};