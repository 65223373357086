import { debugService } from '../../services/utils/debugService';

const performanceMonitor = {
    start: (label) => {
        performance.mark(`${label}-start`);
    },
    end: (label) => {
        performance.mark(`${label}-end`);
        performance.measure(label, `${label}-start`, `${label}-end`);
        const duration = performance.getEntriesByName(label)[0].duration;
        debugService.log(`Performance '${label}': ${duration.toFixed(2)}ms`);
        performance.clearMarks();
        performance.clearMeasures();
    },
    logMemoryUsage: () => {
        if (performance.memory) {
            const memoryUsage = {
                totalJSHeapSize: (performance.memory.totalJSHeapSize / 1048576).toFixed(2),
                usedJSHeapSize: (performance.memory.usedJSHeapSize / 1048576).toFixed(2),
                jsHeapSizeLimit: (performance.memory.jsHeapSizeLimit / 1048576).toFixed(2)
            };
            debugService.log(`Memory usage (MB): Total: ${memoryUsage.totalJSHeapSize}, Used: ${memoryUsage.usedJSHeapSize}, Limit: ${memoryUsage.jsHeapSizeLimit}`);
        } else {
            debugService.log('Memory usage information is not available.');
        }
    }
};

export default performanceMonitor;