import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DebugWindow from '../../../common/DebugWindow/DebugWindow';
import SettingsWindow from '../../../common/SettingsWindow/SettingsWindow';
import { setDebugWindowVisibility, setSettingsWindowVisibility } from '../../../../store/uiSlice';

function DebugSettingsWindows() {
    const dispatch = useDispatch();
    const { isDebugWindowVisible, isSettingsWindowVisible } = useSelector(state => state.ui);
    const userData = useSelector(state => state.session.userData);
    const sessionData = useSelector(state => state.session.sessionData);
    const programData = useSelector(state => state.session.programData);

    const closeDebugWindow = () => dispatch(setDebugWindowVisibility(false));
    const closeSettingsWindow = () => dispatch(setSettingsWindowVisibility(false));

    return (
        <>
            {isDebugWindowVisible && (
                <DebugWindow 
                    isVisible={isDebugWindowVisible} 
                    onClose={closeDebugWindow} 
                />
            )}
            {isSettingsWindowVisible && (
                <SettingsWindow
                    isVisible={isSettingsWindowVisible}
                    onClose={closeSettingsWindow}
                    userData={{
                        id: userData?.userId || 'Not available',
                        username: userData?.nickname || 'Not available',
                        role: userData?.role || 'Not available'
                    }}
                    sessionData={{
                        id: sessionData?.id,
                        programId: sessionData?.program_id
                    }}
                    programData={{
                        id: programData?.id,
                        title: programData?.title,
                        flow_url: programData?.flow_url
                    }}
                />
            )}
        </>
    );
}

export default DebugSettingsWindows;