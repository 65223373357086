import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import "./BodySecondTS.css";
import ActivityContentBrainstorm from "./Activitys/ActivityContentBrainstorm";

export const BodySecondTS = ({
    onClose,
    brainstormingData,
    animationComplete,
    unityInstanceRef,
    handleSendToActivity,
    brainstormingRef,
    isHeadphoneActive
}) => {
    const [activityTitle, setActivityTitle] = useState("Activity");

    useEffect(() => {
        console.log('BodySecondTS: Component mounted');
        return () => {
            console.log('BodySecondTS: Component will unmount');
        };
    }, []);

    useEffect(() => {
        console.log('BodySecondTS: brainstormingRef changed:', brainstormingRef);
    }, [brainstormingRef]);

    return (
        <div className="content-container">
            <div className="content-inner">
                <div className="content-header">
                    <h2>{activityTitle}</h2>
                    <button className="close-button" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>
                <div className="content-body">
                    <ActivityContentBrainstorm
                        ref={brainstormingRef}
                        brainstormingData={brainstormingData}
                        setActivityTitle={setActivityTitle}
                        animationComplete={animationComplete}
                        unityInstanceRef={unityInstanceRef}
                        handleSendToActivity={(text) => {
                            console.log(`BodySecondTS: Received text in handleSendToActivity: ${text}`);
                            handleSendToActivity(text);
                        }}
                        isHeadphoneActive={isHeadphoneActive}
                    />
                </div>
            </div>
        </div>
    );
};

export default BodySecondTS;