export const unityInitialize = (unityContainerRef, setContainerSize, setDevicePixelRatio) => {
  const updateDevicePixelRatio = () => {
    setDevicePixelRatio(window.devicePixelRatio);
  };

  const updateContainerSize = () => {
    if (unityContainerRef.current) {
      const { clientWidth, clientHeight } = unityContainerRef.current;
      setContainerSize({ width: clientWidth, height: clientHeight });
    }
  };

  window.addEventListener('resize', updateContainerSize);
  updateContainerSize();

  const mediaMatcher = window.matchMedia(`screen and (resolution: ${window.devicePixelRatio}dppx)`);
  mediaMatcher.addEventListener('change', updateDevicePixelRatio);

  return () => {
    mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
    window.removeEventListener('resize', updateContainerSize);
  };
};

export const unityHandleLoad = (isLoaded, sendMessage, onUnityLoad) => {
  if (isLoaded) {
    onUnityLoad(sendMessage);
  }
};

export const unityUpdateContainerSize = (unityContainerRef, setContainerSize) => {
  if (unityContainerRef.current) {
    const { clientWidth, clientHeight } = unityContainerRef.current;
    setContainerSize({ width: clientWidth, height: clientHeight });
  }
};

export const unitySendMessageDPR = (isLoaded, sendMessage, devicePixelRatio) => {
    if (isLoaded) {
        sendMessage('GameManager', 'SetDevicePixelRatio', devicePixelRatio);
    }
};

export const unityPlayAudioLipSync = (audioUrl, text, sendMessageToUnity) => {
    if (typeof sendMessageToUnity !== 'function') {
        console.error('sendMessageToUnity is not a function');
        return;
    }

    const audio = new Audio(audioUrl);
    audio.addEventListener('loadedmetadata', () => {
        const duration = audio.duration;
        try {
            sendMessageToUnity('AudioPlayer', 'PlayAudio', JSON.stringify({ url: audioUrl, duration, text }));
        } catch (error) {
            console.error('Error sending message to Unity:', error);
        }
    });
};