import { useState, useCallback, useEffect } from 'react';

export const useDraggable = (onDrag) => {
    const [dragging, setDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const startDrag = useCallback((e) => {
        //console.log('Start drag');
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        setOffset({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        });
        setDragging(true);
        document.body.classList.add('no-select');
    }, []);

    const stopDrag = useCallback(() => {
        //console.log('Stop drag');
        setDragging(false);
        document.body.classList.remove('no-select');
    }, []);

    useEffect(() => {
        if (!dragging) return;

        const handleMouseMove = (e) => {
            //console.log('Mouse move');
            onDrag(e, offset);
        };
        const handleMouseUp = () => stopDrag();

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging, onDrag, stopDrag, offset]);

    return {
        dragging,
        startDrag,
    };
};