import { gql } from '@apollo/client';
import client from '../../apollo-client'; // Путь к вашему Apollo Client

// Мутация для создания сессии
const CREATE_SESSION = gql`
  mutation CreateSession($input: CreateSessionDto!) {
    createSession(input: $input) {
      id
      user_id
      program_id
    }
  }
`;

// Запрос для получения сессии по ID
const GET_SESSION_BY_ID = gql`
  query GetSessionById($where: SessionsResolver_Session_FilterInputType!) {
    session(where: $where) {
      id
      user_id
      program_id
      current_level_id
      created_at
      current_level {
        id
        index
        content
      }
    }
  }
`;

// Запрос для получения всех сессий
const GET_ALL_SESSIONS = gql`
  query GetAllSessions($where: SessionsResolver_Sessions_FilterInputType, $order_by: [SessionsResolver_Sessions_SortingInputType!], $paginate: PaginatorArgsDto) {
    sessions(where: $where, order_by: $order_by, paginate: $paginate) {
      id
      user_id
      program_id
      current_level_id
      created_at
      current_level {
        id
        index
        content
        description_short
        description_long
      }
    }
  }
`;

// Мутация для удаления сессии
const DELETE_SESSION = gql`
  mutation DeleteSession($input: RemoveInputDto!) {
    removeSession(input: $input) {
      id
    }
  }
`;

// Мутация для сохранения истории сессии
const SAVE_SESSION_HISTORY = gql`
  mutation SaveSessionHistory($sessionId: String!, $history: [MessageInputType!]!) {
    saveSessionHistory(sessionId: $sessionId, history: $history) {
      success
      message
    }
  }
`;

// Запрос для загрузки истории сессии
const LOAD_SESSION_HISTORY = gql`
  query LoadSessionHistory($sessionId: String!) {
    loadSessionHistory(sessionId: $sessionId) {
      sender
      type
      text
      titleText
      instructions
      correctAnswer
    }
  }
`;

// Мутация для очистки истории сессии
const CLEAR_SESSION_HISTORY = gql`
  mutation ClearSessionHistory($sessionId: String!) {
    clearSessionHistory(sessionId: $sessionId) {
      success
      message
    }
  }
`;

const UPDATE_SESSION = gql`
  mutation UpdateSession($input: UpdateSessionDto!) {
    updateSession(input: $input) {
      id
      user_id
      program_id
      current_level_id
      created_at
      current_level {
        id
        index
        content
        description_short
        description_long
      }
    }
  }
`;

const INCREMENT_CURRENT_LEVEL = gql`
  mutation IncrementCurrentLevel($input: IncrementCurrentLevelDto!) {
    incrementCurrentLevel(input: $input) {
      id
      index
      content
      description_short
      description_long
      activity_id
      activity_type
      program_id
    }
  }
`;

const RESET_CURRENT_LEVEL = gql`
  mutation ResetCurrentLevel($input: IncrementCurrentLevelDto!) {
    resetCurrentLevel(input: $input) {
      id
      index
      content
      description_short
      description_long
      activity_id
      activity_type
      program_id
    }
  }
`;

export const incrementCurrentLevel = async (sessionId) => {
    try {
        const { data } = await client.mutate({
            mutation: INCREMENT_CURRENT_LEVEL,
            variables: { input: { session_id: sessionId } },
            fetchPolicy: 'network-only'
        });
        console.log("Current level incremented:", data.incrementCurrentLevel);
        return data.incrementCurrentLevel;
    } catch (error) {
        console.error("Error incrementing current level:", error);
        throw error;
    }
};

export const resetCurrentLevel = async (sessionId) => {
    try {
        const { data } = await client.mutate({
            mutation: RESET_CURRENT_LEVEL,
            variables: { input: { session_id: sessionId } },
            fetchPolicy: 'network-only'
        });
        console.log("Current level reset:", data.resetCurrentLevel);
        return data.resetCurrentLevel;
    } catch (error) {
        console.error("Error resetting current level:", error);
        throw error;
    }
};

export const createSession = async (userId, programId) => {
    try {
        const { data } = await client.mutate({
            mutation: CREATE_SESSION,
            variables: { input: { user_id: userId, program_id: programId } },
            fetchPolicy: 'network-only'
        });
        console.log("Session created:", data.createSession);
        return data.createSession;
    } catch (error) {
        console.error("Error creating session:", error);
        throw error;
    }
};

export const getSessionById = async (sessionId) => {
    console.log("Fetching session for ID:", sessionId);
    try {
        const { data } = await client.query({
            query: GET_SESSION_BY_ID,
            variables: { where: { id: { eq: sessionId } } },
            fetchPolicy: 'network-only'
        });
        console.log("Session fetched:", data.session);
        return data.session;
    } catch (error) {
        console.error("Error fetching session:", error);
        throw error;
    }
};

export const getAllSessions = async (filters = {}, orderBy = [], paginate = { per_page: 1000, page: 0 }) => {
    try {
        const { data } = await client.query({
            query: GET_ALL_SESSIONS,
            variables: { where: filters, order_by: orderBy, paginate },
            fetchPolicy: 'network-only'
        });
        console.log("All sessions fetched:", data.sessions);
        return data.sessions;
    } catch (error) {
        console.error("Error fetching all sessions:", error);
        throw error;
    }
};

export const deleteSession = async (sessionId) => {
    try {
        console.log(`Attempting to delete session with ID: ${sessionId}`);
        const { data } = await client.mutate({
            mutation: DELETE_SESSION,
            variables: { input: { id: sessionId } }
        });
        console.log(`Session with ID ${sessionId} successfully deleted:`, data.removeSession);
        return data.removeSession;
    } catch (error) {
        console.error(`Error deleting session with ID ${sessionId}:`, error);
        throw error;
    }
};

export const loadSessionHistory = async (sessionId) => {
    console.log("Loading session history for session:", sessionId);
    try {
        const { data } = await client.query({
            query: LOAD_SESSION_HISTORY,
            variables: { sessionId }
        });
        console.log("Session history loaded successfully");
        return data.loadSessionHistory;
    } catch (error) {
        console.error("Error loading session history:", error);
        throw error;
    }
};

export const saveSessionHistory = async (sessionId, history) => {
    if (!sessionId) {
        console.error("Session ID is undefined");
        throw new Error("Session ID is required to save history");
    }
    console.log("Saving session history for session:", sessionId);

    const formattedHistory = history.map(msg => ({
        sender: msg.sender,
        type: msg.type || 'chat',
        text: msg.text,
        ...(msg.titleText && { titleText: msg.titleText }),
        ...(msg.instructions && { instructions: msg.instructions }),
        ...(msg.correctAnswer && { correctAnswer: msg.correctAnswer })
    }));

    try {
        const { data } = await client.mutate({
            mutation: SAVE_SESSION_HISTORY,
            variables: { sessionId, history: formattedHistory }
        });
        console.log("Session history saved successfully:", data.saveSessionHistory);
        return data.saveSessionHistory;
    } catch (error) {
        console.error("Error saving session history:", error);
        throw error;
    }
};

export const clearSessionHistory = async (sessionId) => {
    console.log("Clearing session history for session:", sessionId);
    try {
        const { data } = await client.mutate({
            mutation: CLEAR_SESSION_HISTORY,
            variables: { sessionId }
        });
        console.log("Session history cleared successfully");
        return data.clearSessionHistory;
    } catch (error) {
        console.error("Error clearing session history:", error);
        throw error;
    }
};

export const updateSession = async (sessionId, updateData) => {
    try {
        const { data } = await client.mutate({
            mutation: UPDATE_SESSION,
            variables: { input: { id: sessionId, ...updateData } },
            fetchPolicy: 'network-only'
        });
        console.log("Session updated:", data.updateSession);
        return data.updateSession;
    } catch (error) {
        console.error("Error updating session:", error);
        throw error;
    }
};