// API
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://interactive-assessment.oblakogroup.ru/api';


// STT настройки
export const SILENCE_THRESHOLD = -50; // dB
export const SPEECH_THRESHOLD = -35; // dB
export const MIN_SPEECH_DURATION = 300; // ms
export const MAX_SILENCE_DURATION = 1000; // ms
export const PRE_RECORDING_BUFFER_DURATION = 1000; // ms (1 секунда)
export const TTS_LANGUAGE = 'ru-RU';
// TTS настройки
export const DEFAULT_VOICE = "echo";
export const MAIN_VOICE = 'echo';
export const ACTIVITY_VOICE = 'onyx';
