import { handleMessageProcessing } from './chatUtils';
import { generateUUID } from '../utils';

export const processMessage = async (text, flowUrl) => {
    const { flowResponse, audioUrl, audioDuration, responseTime } = await handleMessageProcessing(text, flowUrl);

    if (!flowResponse || !flowResponse.messages) {
        throw new Error('Invalid response from handleMessageProcessing');
    }

    const messageDuration = Math.max(audioDuration * 1000, 5000);

    return {
        botMessage: {
            sender: 'Bot',
            text: flowResponse, // Передаем весь объект flowResponse
            audioUrl,
            audioDuration: messageDuration,
            id: generateUUID(),
            isCompleted: false,
            isInterrupted: false
        },
        typingDuration: responseTime * 1000,
        readyToAdvance: flowResponse.ready_to_advance
    };
};