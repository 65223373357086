import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllPrograms,
  createProgram,
  updateProgram,
  deleteProgram,
} from '../../services/api';

export const fetchAllPrograms = createAsyncThunk(
  'programs/fetchAllPrograms',
  async (_, { rejectWithValue }) => {
    try {
      return await getAllPrograms();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addProgram = createAsyncThunk(
  'programs/addProgram',
  async (programData, { rejectWithValue }) => {
    try {
      return await createProgram(programData);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateProgramData = createAsyncThunk(
  'programs/updateProgram',
  async ({ programId, programData }, { rejectWithValue }) => {
    try {
      return await updateProgram(programId, programData);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeProgram = createAsyncThunk(
  'programs/removeProgram',
  async (programId, { rejectWithValue }) => {
    try {
      await deleteProgram(programId);
      return programId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const programsSlice = createSlice({
  name: 'programs',
  initialState: {
    programs: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPrograms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllPrograms.fulfilled, (state, action) => {
        state.programs = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllPrograms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addProgram.fulfilled, (state, action) => {
        state.programs.push(action.payload);
      })
      .addCase(updateProgramData.fulfilled, (state, action) => {
        const index = state.programs.findIndex(
          (program) => program.id === action.payload.id,
        );
        if (index !== -1) {
          state.programs[index] = action.payload;
        }
      })
      .addCase(removeProgram.fulfilled, (state, action) => {
        state.programs = state.programs.filter(
          (program) => program.id !== action.payload,
        );
      });
  },
});

export default programsSlice.reducer;
