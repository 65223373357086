import React from 'react';
import './UnauthorizedPage.css';

const UnauthorizedPage = () => {
    return (
        <div className="unauthorized-container">
            <h1>Unauthorized</h1>
            <p>You do not have permission to view this page.</p>
        </div>
    );
};

export default UnauthorizedPage;
