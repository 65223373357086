import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BodyTS from '../../../TrainingSpace/BodyTS/BodyTS';
import BodySecondTS from '../../../TrainingSpace/BodySecondTS/BodySecondTS';
import { useUnity } from '../../../../services/unity';
import { setBodySecondVisibility, setBodySecondMounted, setActiveComponent } from '../../../../store/uiSlice';
import { useMessageHandling } from './hooks/useMessageHandling';
import { clearSttInput } from '../../../../store/sttSlice';

function ChatContainer() {
    const dispatch = useDispatch();
    const { 
        isBodySecondVisible, 
        isBodySecondMounted, 
        activeComponent
    } = useSelector(state => state.ui);
    const sttInput = useSelector(state => state.stt.sttInput);
    const { messages, isTyping, typingDuration, handleSendMessageWrapper } = useMessageHandling();
    
    const [brainstormingData, setBrainstormingData] = useState(null);
    const [animationComplete, setAnimationComplete] = useState(false);
    const { UCONTEXT_sendMessageToUnity, UCONTEXT_stopAndClearAudio, activityUnityLoaded } = useUnity();
    const isTogglingRef = useRef(false);
    const toggleTimeoutRef = useRef(null);
    const brainstormingRef = useRef(null);

    const handleToggleAdditionalInfo = useCallback((data) => {
        console.log("ChatContainer: handleToggleAdditionalInfo called", { isToggling: isTogglingRef.current, isBodySecondVisible });

        if (isTogglingRef.current) {
            console.log("ChatContainer: Toggle operation in progress, ignoring duplicate call");
            return;
        }

        isTogglingRef.current = true;

        if (toggleTimeoutRef.current) {
            clearTimeout(toggleTimeoutRef.current);
        }

        if (isBodySecondVisible) {
            console.log("ChatContainer: Closing BodySecondTS");
            dispatch(setBodySecondVisibility(false));
            dispatch(setActiveComponent('BodyTS'));
            if (activityUnityLoaded) {
                UCONTEXT_stopAndClearAudio('activity');
            }
            toggleTimeoutRef.current = setTimeout(() => {
                dispatch(setBodySecondMounted(false));
                setAnimationComplete(false);
                isTogglingRef.current = false;
                console.log("ChatContainer: BodySecondTS closed and lock released");
            }, 500);
        } else {
            console.log("ChatContainer: Opening BodySecondTS");
            setBrainstormingData(data);
            dispatch(setBodySecondMounted(true));
            dispatch(setActiveComponent('BodySecondTS'));

            toggleTimeoutRef.current = setTimeout(() => {
                dispatch(setBodySecondVisibility(true));
                setTimeout(() => {
                    setAnimationComplete(true);
                    if (activityUnityLoaded) {
                        UCONTEXT_sendMessageToUnity('CharacterSelect', 'ChangeCharacter', 'stive', 'activity');
                    }
                    isTogglingRef.current = false;
                    console.log("ChatContainer: BodySecondTS opened and lock released");
                }, 500);
            }, 50);
        }
    }, [isBodySecondVisible, UCONTEXT_sendMessageToUnity, UCONTEXT_stopAndClearAudio, activityUnityLoaded, dispatch]);

    const handleSendToActivity = useCallback((text) => {
        if (isBodySecondMounted && activeComponent === 'BodySecondTS') {
            if (brainstormingRef.current && brainstormingRef.current.handleSubmit) {
                brainstormingRef.current.handleSubmit(null, text);
            } else {
                console.log('ChatContainer: brainstormingRef or handleSubmit is not available');
            }
        } else {
            console.log('ChatContainer: BodySecondTS is not mounted or not active');
        }
    }, [isBodySecondMounted, activeComponent]);

    const handleSttInput = useCallback((text) => {
        if (activeComponent === 'BodyTS') {
            handleSendMessageWrapper(text);
        } else if (activeComponent === 'BodySecondTS') {
            handleSendToActivity(text);
        }
        dispatch(clearSttInput());
    }, [activeComponent, handleSendMessageWrapper, handleSendToActivity, dispatch]);

    useEffect(() => {
        if (sttInput) {
            handleSttInput(sttInput);
        }
    }, [sttInput, handleSttInput]);

    useEffect(() => {
        return () => {
            if (toggleTimeoutRef.current) {
                clearTimeout(toggleTimeoutRef.current);
            }
        };
    }, []);

    return (
        <div className="BodyTS">
            <div className={`BodyTS-content ${isBodySecondVisible ? 'double' : 'single'}`}>
                <div className="BodyTS-main">
                    <BodyTS
                        title="Chat"
                        messages={messages}
                        handleSendMessageWrapper={handleSendMessageWrapper}
                        isTyping={isTyping}
                        onToggleAdditionalInfo={handleToggleAdditionalInfo}
                        isBodySecondVisible={isBodySecondVisible}
                        isActive={activeComponent === 'BodyTS'}
                        isHeadphoneActive={!isBodySecondVisible}
                    />
                </div>
                {isBodySecondMounted && (
                    <div className="BodyTS-secondary">
                        <BodySecondTS
                            onClose={() => handleToggleAdditionalInfo(null)}
                            brainstormingData={brainstormingData}
                            animationComplete={animationComplete}
                            isActive={activeComponent === 'BodySecondTS'}
                            brainstormingRef={brainstormingRef}
                            handleSendToActivity={handleSendToActivity}
                            isHeadphoneActive={isBodySecondVisible}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(ChatContainer);