import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sttInput: '',
};

const sttSlice = createSlice({
  name: 'stt',
  initialState,
  reducers: {
    setSttInput: (state, action) => {
      state.sttInput = action.payload;
    },
    clearSttInput: (state) => {
      state.sttInput = '';
    },
  },
});

export const { setSttInput, clearSttInput } = sttSlice.actions;

export default sttSlice.reducer;