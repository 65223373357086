import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ActivityContentBrainstorm.css';
import sendIcon from "../../../../assets/images/TS/icon-send-blue.svg";
import ActivityWebcamUnityTS from '../../WebcamUnityTS/ActivityWebcamUnityTS';
import MindMap from './MindMap';
import { sendToFlow } from '../../../../services/api';
import { handleMessageProcessing } from '../../../../services/chat/chatUtils';
import { useUnity } from '../../../../services/unity';
import { ACTIVITY_VOICE } from '../../../../services/constants';

const ActivityContentBrainstorm = React.forwardRef(({ brainstormingData, setActivityTitle, animationComplete, isActive, onSttInput, handleSendToActivity, isHeadphoneActive }, ref) => {
    const [newIdea, setNewIdea] = useState('');
    const [mindmapString, setMindmapString] = useState('');
    const containerRef = useRef(null);
    const { activityUnityLoaded, UCONTEXT_playAudioLipSync, unityInstances } = useUnity();
    const [isWebcamVisible, setIsWebcamVisible] = useState(false);
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const isFetchingRef = useRef(false);

    useEffect(() => {
        if (animationComplete) {
            setTimeout(() => setIsWebcamVisible(true), 300);
        }
    }, [animationComplete]);

    const fetchFlowData = useCallback(async () => {
        if (!brainstormingData || hasFetchedData || isFetchingRef.current) return;

        isFetchingRef.current = true;
        console.log('ActivityContentBrainstorm: Fetching flow data...');

        try {
            setActivityTitle("Brainstorming");

            if (brainstormingData.assistantFlow?.params?.element_property_value) {
                const result = await handleMessageProcessing(
                    'start_the_activity',
                    brainstormingData.assistantFlow.flow_url,
                    ACTIVITY_VOICE
                );
                console.log('Response from Assistant Flow:', result.flowResponse);

                if (result.audioUrl && unityInstances['activity'] && unityInstances['activity'].isLoaded) {
                    const textContent = result.flowResponse.messages?.[0]?.content || result.flowResponse.completion || '';
                    UCONTEXT_playAudioLipSync(result.audioUrl, textContent, 'activity');
                }
            }

            if (brainstormingData.ideaExtractorFlow?.params?.element_property_value) {
                const extractorTweaks = {
                    [brainstormingData.ideaExtractorFlow.params.element_id]: {
                        [brainstormingData.ideaExtractorFlow.params.element_property]: brainstormingData.ideaExtractorFlow.params.element_property_value
                    }
                };
                const extractorResponse = await sendToFlow(
                    'start_the_activity',
                    brainstormingData.ideaExtractorFlow.flow_url,
                    extractorTweaks
                );
                console.log('Initial response from Idea Extractor Flow:', extractorResponse);

                if (extractorResponse?.response) {
                    setMindmapString(extractorResponse.response);
                }
            }

            setHasFetchedData(true);
        } catch (error) {
            console.error('Error fetching flow data:', error);
        } finally {
            isFetchingRef.current = false;
        }
    }, [brainstormingData, setActivityTitle, UCONTEXT_playAudioLipSync, unityInstances, hasFetchedData]);

    useEffect(() => {
        if (brainstormingData && !mindmapString && !hasFetchedData) {
            fetchFlowData();
        }
    }, [brainstormingData, mindmapString, fetchFlowData, hasFetchedData]);

    useEffect(() => {
        if (animationComplete) {
            console.log('Animation complete');
        }
    }, [animationComplete]);

    const handleSubmit = async (e, sttInput = '') => {
        e?.preventDefault();
        const ideaToSubmit = sttInput || newIdea.trim();
        console.log(`ActivityContentBrainstorm: Submitting idea: ${ideaToSubmit}`);

        if (ideaToSubmit && brainstormingData?.ideaExtractorFlow) {
            try {
                // Отправка в Idea Extractor Flow
                const extractorTweaks = {
                    [brainstormingData.ideaExtractorFlow.params.element_id]: {
                        [brainstormingData.ideaExtractorFlow.params.element_property]: ideaToSubmit
                    }
                };
                const extractorResponse = await sendToFlow(
                    ideaToSubmit,
                    brainstormingData.ideaExtractorFlow.flow_url,
                    extractorTweaks
                );
                console.log('Response from Idea Extractor Flow:', extractorResponse);

                // Отправка в Assistant Flow
                const assistantTweaks = {
                    [brainstormingData.assistantFlow.params.element_id]: {
                        [brainstormingData.assistantFlow.params.element_property]: ideaToSubmit
                    }
                };
                const result = await handleMessageProcessing(
                    ideaToSubmit,
                    brainstormingData.assistantFlow.flow_url,
                    ACTIVITY_VOICE,
                    assistantTweaks
                );
                console.log('Response from Assistant Flow:', result);

                if (result.audioUrl && unityInstances['activity'] && unityInstances['activity'].isLoaded) {
                    const textContent = result.flowResponse.messages?.[0]?.content || result.flowResponse.completion || '';
                    UCONTEXT_playAudioLipSync(result.audioUrl, textContent, 'activity');
                }

                if (extractorResponse?.response) {
                    setMindmapString(extractorResponse.response);
                } else {
                    console.error('Invalid response from Idea Extractor Flow');
                }

                setNewIdea('');
            } catch (error) {
                console.error('Error processing idea:', error);
            }
        } else {
            console.log('No idea to submit or missing brainstormingData');
        }
    };

    useEffect(() => {
        if (isActive && handleSendToActivity) {
            handleSendToActivity(handleSubmit);
        }
    }, [isActive, handleSendToActivity]);

    React.useImperativeHandle(ref, () => ({
        handleSubmit: (event, text) => {
            console.log(`ActivityContentBrainstorm: handleSubmit called with text: ${text}`);
            handleSubmit(event, text);
        }
    }));

    React.useEffect(() => {
        console.log('ActivityContentBrainstorm: Component initialized with ref');
    }, []);


    if (!brainstormingData) {
        return <div>Loading... (No brainstorming data available)</div>;
    }

    return (
        <div className="brainstorm-container" ref={containerRef}>
            {activityUnityLoaded && animationComplete && (
                <ActivityWebcamUnityTS
                    containerRef={containerRef}
                    isVisible={isWebcamVisible}
                    isHeadphoneActive={isHeadphoneActive}
                />
            )}
            <div className="brainstorm-content">
                <div className="brainstorm-header">
                    <h2 className="brainstorm-title">{brainstormingData.title}</h2>
                </div>
                <div className="brainstorm-description">
                    <p className="brainstorm-text">{brainstormingData.description}</p>
                </div>
                <div className="brainstorm-svg">
                    {mindmapString && <MindMap mindmapString={mindmapString} />}
                </div>
            </div>
            <form onSubmit={handleSubmit} className="brainstorm-form">
                <div className="input-container">
                    <label htmlFor="idea-input" className="input-label">Your idea</label>
                    <input
                        id="idea-input"
                        type="text"
                        value={newIdea}
                        onChange={(e) => setNewIdea(e.target.value)}
                        placeholder="Enter your idea"
                        className="idea-input"
                    />
                </div>
                <button type="submit" className="submit-button">
                    <img src={sendIcon} alt="Send Icon" className="chatbox-footer-send-icon" />
                    <span>Send</span>
                </button>
            </form>
        </div>
    );
});

export default React.memo(ActivityContentBrainstorm);