import axios from 'axios';

export const fetchJson = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await fetch(url, { ...options, headers });
        if (response.status === 401) {
            window.location.href = '/login';
            return;
        }
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

export const apiRequest = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
        Authorization: `Bearer ${token}`,
    };
    try {
        const response = await axios({
            url,
            ...options,
            headers,
            responseType: options.responseType || 'json',
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('API request error:', error.response.status, error.response.data);

            if (error.response.status === 401) {
                window.location.href = '/login';
                return;
            }

            if (error.response.status === 400) {
                console.error('Bad Request. Request data:', options.data);
                console.error('Response data:', error.response.data);
            }

            throw new Error(JSON.stringify(error.response.data) || 'Server error');
        } else if (error.request) {
            console.error('No response received:', error.request);
            throw new Error('No response received from server');
        } else {
            console.error('Error setting up request:', error.message);
            throw error;
        }
    }
};