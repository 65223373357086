import { gql } from '@apollo/client';
import client from '../../apollo-client'; // Путь к вашему Apollo Client

// Запрос для получения всех flows
const GET_ALL_FLOWS = gql`
  query GetAllFlows($author_id: String) {
    flows(where: { author_id: { eq: $author_id } }) {
      id
      title
      description
      provider
      model
      temperature
      system_prompt
      author_id
      external_flow_id
    }
  }
`;

// Мутация для создания flow
const CREATE_FLOW = gql`
  mutation CreateFlow($input: CreateFlowDto!) {
    createFlow(input: $input) {
      title
      description
      provider
      model
      temperature
      system_prompt
      author_id
      external_flow_id
    }
  }
`;

// Запрос для получения flow по ID
const GET_FLOW_BY_ID = gql`
  query GetFlowById($where: FlowResolver_Flows_FilterInputType!) {
    flows(where: $where) {
      id
      title
      description
      provider
      model
      temperature
      system_prompt
      author_id
      external_flow_id
    }
  }
`;

// Мутация для обновления flow
const UPDATE_FLOW = gql`
  mutation UpdateFlow($input: UpdateFlowDto!) {
    updateFlow(input: $input) {
      id
      title
      description
      provider
      model
      temperature
      system_prompt
      author_id
      external_flow_id
    }
  }
`;

// Мутация для удаления flow
const DELETE_FLOW = gql`
  mutation DeleteFlow($input: RemoveInputDto!) {
    removeFlow(input: $input) {
      id
    }
  }
`;

export const createFlow = async (input) => {
  const { data } = await client.mutate({
    mutation: CREATE_FLOW,
    variables: { input },
  });
  return data.createFlow;
};

export const getFlowById = async (flowId) => {
  console.log('Fetching flow data for ID:', flowId);
  try {
    const { data } = await client.query({
      query: GET_FLOW_BY_ID,
      variables: { where: { id: { eq: flowId } } },
    });
    console.log('Flow data fetched:', data.flow);
    return data.flow;
  } catch (error) {
    console.error('Error fetching flow data:', error);
    throw error;
  }
};

export const deleteFlow = async (flowId) => {
  console.log(`Attempting to delete flow with ID: ${flowId}`);
  try {
    const { data } = await client.mutate({
      mutation: DELETE_FLOW,
      variables: { input: { id: flowId } },
    });
    console.log(
      `Flow with ID ${flowId} successfully deleted:`,
      data.removeFlow,
    );
    return data.removeFlow;
  } catch (error) {
    console.error(`Error deleting flow with ID ${flowId}:`, error);
    throw error;
  }
};

export const getAllFlows = async (author_id) => {
  try {
    const { data } = await client.query({
      query: GET_ALL_FLOWS,
      fetchPolicy: 'network-only',
      variables: { author_id },
    });
    console.log('All flows fetched:', data.flows);
    return data.flows;
  } catch (error) {
    console.error('Error fetching all flows:', error);
    throw error;
  }
};

export const updateFlow = async (flowId, flowData) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_FLOW,
      variables: { input: { id: flowId, ...flowData } },
    });
    console.log('Flow updated:', data.updateFlow);
    return data.updateFlow;
  } catch (error) {
    console.error('Error updating flow:', error);
    throw error;
  }
};
