import React, { useEffect, useRef, useState } from 'react';
import './styles/ContextMenu.css';

const ContextMenu = ({ show, onClose, children }) => {
    const menuRef = useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const handleMouseClick = (event) => {
            if (show && !menuRef.current.contains(event.target)) {
                const offsetX = menuRef.current.offsetWidth / 2;
                const offsetY = menuRef.current.offsetHeight;
                setPosition({
                    top: event.clientY - offsetY - 30,
                    left: event.clientX - offsetX,
                });
                document.removeEventListener('click', handleMouseClick);
            }
        };

        if (show) {
            document.addEventListener('click', handleMouseClick);
        }

        return () => {
            document.removeEventListener('click', handleMouseClick);
        };
    }, [show]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show, onClose]);

    return (
        <div
            className={`context-menu ${show ? 'show' : ''}`}
            ref={menuRef}
            style={{ top: `${position.top}px`, left: `${position.left}px` }}
        >
            {children}
        </div>
    );
};

export default ContextMenu;