import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';
import { unityPlayAudioLipSync } from './UnityHelpers';
import UnityContainer from '../../components/UnityContainer/UnityContainer';

const UnityContext = createContext();

export const UnityProvider = ({ children }) => {
    const [unityInstances, setUnityInstances] = useState({});
    const unityInstancesRef = useRef({});
    const [activityUnityLoaded, setActivityUnityLoaded] = useState(false);

    useEffect(() => {
        unityInstancesRef.current = unityInstances;
    }, [unityInstances]);

    const setUnitySendMessage = useCallback((sendMsgFunc, instanceId) => {
        setUnityInstances(prev => {
            const newInstances = {
                ...prev,
                [instanceId]: { ...prev[instanceId], sendMessage: sendMsgFunc, isLoaded: true }
            };
            console.log(`Unity instance ${instanceId} is now loaded and ready`);
            console.log('Current Unity instances:', newInstances);
            return newInstances;
        });
        if (instanceId === 'activity') {
            setActivityUnityLoaded(true);
        }
    }, []);

    const setUnityLoaded = useCallback((loaded, instanceId) => {
        setUnityInstances(prev => {
            const newInstances = {
                ...prev,
                [instanceId]: { ...prev[instanceId], isLoaded: loaded }
            };
            console.log(`Unity instance ${instanceId} loaded state set to: ${loaded}`);
            console.log('Current Unity instances:', newInstances);
            return newInstances;
        });
    }, []);

    const unloadUnityInstance = useCallback((instanceId) => {
        setUnityInstances(prev => {
            const newInstances = { ...prev };
            if (newInstances[instanceId]) {
                if (newInstances[instanceId].unityInstance) {
                    newInstances[instanceId].unityInstance.Quit().then(() => {
                        console.log(`Unity instance ${instanceId} has been properly quit`);
                        window.gc();
                    });
                }
                delete newInstances[instanceId];
            }
            console.log(`Unity instance ${instanceId} has been unloaded`);
            console.log('Current Unity instances:', newInstances);
            return newInstances;
        });
    }, []);

    const UCONTEXT_playAudioLipSync = useCallback((audioUrl, text, instanceId) => {
        const instance = unityInstancesRef.current[instanceId];
        if (instance && instance.sendMessage && instance.isLoaded) {
            unityPlayAudioLipSync(audioUrl, text, instance.sendMessage);
        } else {
            console.warn(`Unity instance ${instanceId} is not ready for audio playback`);
        }
    }, []);

    const UCONTEXT_stopAndClearAudio = useCallback((instanceId) => {
        const instance = unityInstancesRef.current[instanceId];
        if (instance && instance.sendMessage && instance.isLoaded) {
            console.log(`Stopping and clearing audio for Unity instance ${instanceId}`);
            instance.sendMessage('AudioPlayer', 'StopAndClearAudio', 'Stop');
        } else {
            console.warn(`Unity instance ${instanceId} is not ready or doesn't exist`);
        }
    }, []);

    const UCONTEXT_sendMessageToUnity = useCallback((gameObjectName, methodName, value, instanceId) => {
        const instance = unityInstancesRef.current[instanceId];
        if (instance && instance.sendMessage && instance.isLoaded) {
            console.log(`Sending message to Unity instance ${instanceId}:`, { gameObjectName, methodName, value });
            instance.sendMessage(gameObjectName, methodName, value);
        } else {
            console.warn(`Unity instance ${instanceId} is not ready or doesn't exist`);
        }
    }, []);

    const contextValue = {
        setUnitySendMessage,
        setUnityLoaded,
        unloadUnityInstance,
        UCONTEXT_playAudioLipSync,
        UCONTEXT_stopAndClearAudio,
        UCONTEXT_sendMessageToUnity,
        unityInstances,
        activityUnityLoaded
    };

    return (
        <UnityContext.Provider value={contextValue}>
            {children}
            <div style={{ position: 'absolute', left: '-9999px', top: '-9999px', width: '1px', height: '1px', overflow: 'hidden' }}>
                <UnityContainer
                    onUnityLoad={({ isLoaded, sendMessage }) => {
                        if (isLoaded && sendMessage) {
                            setUnitySendMessage(sendMessage, 'activity');
                        }
                    }}
                    containerStyle={{ width: '180px', height: '180px' }}
                    instanceId="activity"
                />
            </div>
        </UnityContext.Provider>
    );
};

export const useUnity = () => useContext(UnityContext);