import React from 'react';
import './styles/BotMessageButton.css';
import iconBrain from '../../../assets/images/TS/icon-brain.svg';
import iconArrowRight from '../../../assets/images/TS/icon-arrow-right.svg';

const iconMap = {
    brain: iconBrain,
    // Добавьте здесь другие иконки, если они понадобятся в будущем
};

const BotMessageButton = ({ onClick, isOpen, title, subtitle, icon }) => {
    const IconComponent = iconMap[icon] || iconBrain;

    const handleClick = (e) => {
        e.preventDefault();
        //console.log("BotMessageButton: handleClick called"); // Добавим лог для отладки
        onClick();
    };

    return (
        <button
            onClick={handleClick}
            className="bot-message-button"
        >
            <img src={IconComponent} alt={`${icon} icon`} className={`icon-${icon}`} />
            <div className="button-content">
                <span className="button-title">{title}</span>
                <span className="button-subtitle">{subtitle}</span>
            </div>
            <div className="icon-arrow">
                <img src={iconArrowRight} alt="Arrow right" />
            </div>
        </button>
    );
};

export default BotMessageButton;