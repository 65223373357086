import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageRefresh = () => {
    const location = useLocation();

    useEffect(() => {
        const shouldRefresh = sessionStorage.getItem('shouldRefreshClientPageTS');
        if (shouldRefresh === 'true') {
            sessionStorage.removeItem('shouldRefreshClientPageTS');
            window.location.reload();
        }
    }, [location.pathname]);
};

export default usePageRefresh;