import { useCallback } from 'react';
import MessageQueueManager from '../MessageQueueManager';

export const useMessageQueue = (setMessages, playAudio) => {
    const messageQueue = MessageQueueManager({ setMessages, playAudio });

    const addToQueue = useCallback((message) => {
        messageQueue.addToQueue(message);
    }, [messageQueue]);

    return { addToQueue };
};