import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createFlow,
  deleteFlow,
  getAllFlows,
  updateFlow,
} from '../../services/api/flows';
import { jwtDecode } from 'jwt-decode';
import { withErrorHandler } from '../../utils/error-handler';

export const fetchAllFlows = createAsyncThunk(
  'flows/fetchAllFlows',
  withErrorHandler(async () => {
    const token = localStorage.getItem('token');

    const decoded = jwtDecode(token);

    return await getAllFlows(decoded.id);
  }),
);

export const addFlow = createAsyncThunk(
  'flows/addFlow',
  withErrorHandler(async (flowData) => {
    const token = localStorage.getItem('token');

    const decoded = jwtDecode(token);

    return await createFlow({ ...flowData, author_id: decoded.id });
  }),
);

export const updateFlowData = createAsyncThunk(
  'flows/updateFlow',
  withErrorHandler(async ({ flowId, flowData }) => {
    return await updateFlow(flowId, flowData);
  }),
);

export const removeFlow = createAsyncThunk(
  'flows/removeFlow',
  withErrorHandler(async (flowId) => {
    return await deleteFlow(flowId);
  }),
);

const flowsSlice = createSlice({
  name: 'flows',
  initialState: {
    flows: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFlows.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllFlows.fulfilled, (state, action) => {
        state.flows = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllFlows.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addFlow.fulfilled, (state, action) => {
        state.flows.push(action.payload);
      })
      .addCase(updateFlowData.fulfilled, (state, action) => {
        const index = state.flows.findIndex(
          (flow) => flow.id === action.payload.id,
        );
        if (index !== -1) {
          state.flows[index] = action.payload;
        }
      })
      .addCase(removeFlow.fulfilled, (state, action) => {
        state.flows = state.flows.filter((flow) => flow.id !== action.payload);
      });
  },
});

export default flowsSlice.reducer;
