import { useCallback, useRef, useEffect, useState } from 'react';
import { startWebSpeechRecording, stopWebSpeechRecording, isRecognitionActive } from '../../../../services/audio/webSpeechRecorder';
import { debugService } from '../../../../services/utils/debugService';

export const useRecordingActions = (recordingMode, activateMic, deactivateMic, onFinalTranscript, language) => {
    const [isListening, setIsListening] = useState(false);
    const webSpeechRecognitionRef = useRef(null);

    const stopRecording = useCallback(() => {
        debugService.log('Остановка записи');
        stopWebSpeechRecording();
        deactivateMic();
        setIsListening(false);
    }, [deactivateMic]);

    const startRecording = useCallback(async () => {
        debugService.log('Начало записи');
        if (!isListening) {
            try {
                await startWebSpeechRecording(onFinalTranscript, language);
                activateMic();
                setIsListening(true);
            } catch (error) {
                debugService.log(`Ошибка при начале записи: ${error.message}`);
            }
        } else {
            debugService.log('Запись уже идет');
        }
    }, [onFinalTranscript, activateMic, language, isListening]);

    const handleMicClick = useCallback(() => {
        debugService.log(`Клик по микрофону. Режим: ${recordingMode}`);
        if (recordingMode === 'listening') {
            if (isListening) {
                stopRecording();
            } else {
                startRecording();
            }
        }
    }, [recordingMode, isListening, startRecording, stopRecording]);

    const handleMicPressStart = useCallback(() => {
        debugService.log(`Начало нажатия на микрофон. Режим: ${recordingMode}`);
        if (recordingMode === 'hold' && !isListening) {
            startRecording();
        }
    }, [recordingMode, isListening, startRecording]);

    const handleMicPressEnd = useCallback(() => {
        debugService.log(`Конец нажатия на микрофон. Режим: ${recordingMode}`);
        if (recordingMode === 'hold' && isListening) {
            stopRecording();
        }
    }, [recordingMode, isListening, stopRecording]);

    useEffect(() => {
        const checkRecognitionState = setInterval(() => {
            const active = isRecognitionActive();
            setIsListening(active);
        }, 100);

        return () => {
            clearInterval(checkRecognitionState);
            stopRecording();
        };
    }, [stopRecording]);

    return {
        handleMicClick,
        handleMicPressStart,
        handleMicPressEnd,
        stopRecording,
        isListening
    };
};