export const getNodeData = (type, users, programs, onFieldChange, onButtonClick, onDelete) => {
  let nodeData = {
    onFieldChange,
    onButtonClick,
    onDelete,
    users,
    programs,
  };

  switch(type) {
    case 'user':
      nodeData.outputs = [{ id: 'userId', label: 'User ID', color: '#ff6b6b' }];
      nodeData.fields = [
        {
          id: 'selectUser',
          type: 'select',
          label: 'Select User',
          options: users.map(user => ({ value: user.email, label: user.username })),
          value: '',
        },
        { id: 'createUser', type: 'button', label: 'Create User' },
      ];
      break;
    case 'session':
      nodeData.inputs = [
        { id: 'userId', label: 'User ID', color: '#ff6b6b' },
        { id: 'programId', label: 'Program ID', color: '#4ecdc4' },
        { id: 'levelIndex', label: 'Level INDEX', color: '#45b7d1' }
      ];
      nodeData.fields = [
        { id: 'createSession', type: 'button', label: 'Create Session' },
      ];
      break;
    case 'program':
      nodeData.inputs = [{ id: 'levelId', label: 'Level ID', color: '#9b59b6' }];
      nodeData.outputs = [{ id: 'programId', label: 'Program ID', color: '#4ecdc4' }];
      nodeData.fields = [
        {
          id: 'selectProgram',
          type: 'select',
          label: 'Select Program',
          options: programs.map(program => ({ value: program.id.toString(), label: program.title })),
          value: '',
        },
        { id: 'title', type: 'input', label: 'Title', value: '' },
        { id: 'flow_url', type: 'input', label: 'Flow URL', value: '' },
        { id: 'createProgram', type: 'button', label: 'Create Program' },
      ];
      break;
    case 'activity':
      nodeData.outputs = [{ id: 'activityId', label: 'Activity ID', color: '#ffa500' }];
      break;
    case 'level':
      nodeData.inputs = [
        { id: 'programId', label: 'Program ID', color: '#4ecdc4' },
        { id: 'activityId', label: 'Activity ID', color: '#ffa500' },
      ];
      nodeData.outputs = [
        { id: 'levelId', label: 'Level ID', color: '#9b59b6' },
        { id: 'levelIndex', label: 'Level INDEX', color: '#45b7d1' },
      ];
      break;
    default:
      nodeData.inputs = [];
      nodeData.outputs = [];
  }

  return nodeData;
};