import { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUnity } from '../../../../../services/unity';
import { processMessage } from '../../../../../services/chat/messageProcessingService';
import { useMessageQueue } from './useMessageQueue';
import { generateUUID } from '../../../../../services/utils';

export function useMessageHandling() {
    const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [typingDuration, setTypingDuration] = useState(0);
    const [flowUrl, setFlowUrl] = useState(null);
    const programData = useSelector(state => state.session.programData);

    const { UCONTEXT_playAudioLipSync, unityInstances } = useUnity();
    const startMessageSent = useRef(false);

    const { addToQueue } = useMessageQueue(setMessages, UCONTEXT_playAudioLipSync);

    useEffect(() => {
        if (programData && programData.flow_url) {
            setFlowUrl(programData.flow_url);
        }
    }, [programData]);

    const handleSendMessageWrapper = useCallback(async (text) => {
        if (!flowUrl) {
            console.error('Flow URL is not defined');
            return;
        }

        setIsTyping(true);

        try {
            if (text === 'start_the_course') {
                addToQueue({
                    sender: 'System',
                    text: `Start of the ${programData.title} course`,
                    type: 'system',
                    id: generateUUID(),
                    isStartMessage: true
                });
            } else {
                addToQueue({
                    sender: 'You',
                    text,
                    type: 'chat',
                    id: generateUUID(),
                    isStartMessage: false
                });
            }

            const result = await processMessage(text, flowUrl);

            if (result && result.botMessage) {
                const { botMessage, typingDuration: newTypingDuration } = result;

                setMessages(prevMessages => prevMessages.map(msg => ({ ...msg, isInterrupted: true })));
                setTypingDuration(newTypingDuration);

                addToQueue({
                    ...botMessage,
                    sender: 'Bot',
                    isStartMessage: false
                });

                if (botMessage.audioUrl && unityInstances['main-chat'] && unityInstances['main-chat'].isLoaded) {
                    UCONTEXT_playAudioLipSync(botMessage.audioUrl, botMessage.text, 'main-chat');
                }

                setTimeout(() => {
                    setMessages(prevMessages => prevMessages.map(msg =>
                        msg.id === botMessage.id ? { ...msg, isCompleted: true } : msg
                    ));
                }, botMessage.audioDuration + 100);
            } else {
                console.error('Invalid response from processMessage:', result);
            }
        } catch (error) {
            console.error('Error in handleSendMessageWrapper:', error);
            addToQueue({
                sender: 'Bot',
                text: 'Извините, произошла ошибка при обработке сообщения. Пожалуйста, попробуйте еще раз.',
                id: generateUUID(),
                isCompleted: true,
                isInterrupted: false,
                isStartMessage: false
            });
        } finally {
            setIsTyping(false);
        }
    }, [flowUrl, addToQueue, UCONTEXT_playAudioLipSync, unityInstances, programData]);

    useEffect(() => {
        if (flowUrl && unityInstances['main-chat'] && unityInstances['main-chat'].isLoaded && !startMessageSent.current) {
            handleSendMessageWrapper('start_the_course');
            startMessageSent.current = true;
        }
    }, [flowUrl, unityInstances, handleSendMessageWrapper]);

    return { messages, isTyping, typingDuration, handleSendMessageWrapper, setMessages };
}