import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getAllLevels, createLevel, updateLevel, deleteLevel} from '../../services/api/levels';
import {
  createBrainstorm,
  createDiscussion,
  createRoleplay,
  createTheory,
  createVideo, updateBrainstorm, updateDiscussion, updateRoleplay, updateTheory, updateVideo
} from "../../services/api/activities";
import {pick} from "../../utils/object.helper";
import {
  EActivityType,
  initialBaseFormData,
  initialBrainstormFormData, initialRoleplayFormData, initialVideoFormData
} from "../../components/pages/Dashboard/components/LevelsComponent";
import {withErrorHandler} from "../../utils/error-handler";

export const fetchAllLevels = createAsyncThunk(
  'levels/fetchAllLevels',
  withErrorHandler(() => getAllLevels()),
);

export const addLevel = createAsyncThunk(
  'levels/addLevel',
  withErrorHandler(async (levelData) => {
      let activity_id = null;
      switch (levelData.activity_type) {
        case EActivityType.THEORY:
          activity_id = await createTheory({
            activity_template_id: levelData.activity_template_id
          });
          break;
        case EActivityType.BRAINSTORM:
          activity_id = await createBrainstorm({
            ...pick(levelData, Object.keys(initialBrainstormFormData)),
            activity_template_id: levelData.activity_template_id
          })
          break;
        case EActivityType.ROLEPLAY:
          activity_id = await createRoleplay({
            ...pick(levelData, Object.keys(initialRoleplayFormData)),
            activity_template_id: levelData.activity_template_id
          })
          break;
        case EActivityType.DISCUSSION:
          activity_id = await createDiscussion({
            activity_template_id: levelData.activity_template_id
          })
          break;
        case EActivityType.VIDEO:
          activity_id = await createVideo({
            ...pick(levelData, Object.keys(initialVideoFormData)),
            activity_template_id: levelData.activity_template_id
          })
      }

      return await createLevel({
        ...pick(levelData, Object.keys(initialBaseFormData)),
        activity_type: levelData.activity_type.toUpperCase(),
        activity_id
      });
  })
);

export const updateLevelData = createAsyncThunk(
    'levels/updateLevel',
    withErrorHandler(async (levelData) => {
      if (levelData.activity_id) {
        switch (levelData.activity_type) {
          case EActivityType.BRAINSTORM:
            await updateBrainstorm({
              ...pick(levelData, [...Object.keys(initialBrainstormFormData)]),
              id: levelData.activity_id,
              activity_template_id: levelData.activity_template_id
            })
            break;
          case EActivityType.DISCUSSION:
            await updateDiscussion({
              id: levelData.activity_id,
              activity_template_id: levelData.activity_template_id
            })
            break;
          case EActivityType.ROLEPLAY:
            await updateRoleplay({
              ...pick(levelData, [...Object.keys(initialRoleplayFormData)]),
              id: levelData.activity_id,
              activity_template_id: levelData.activity_template_id
            })
            break;
          case EActivityType.THEORY:
            await updateTheory({
              id: levelData.activity_id,
              activity_template_id: levelData.activity_template_id
            })
            break;
          case EActivityType.VIDEO:
            await updateVideo({
              ...pick(levelData, [...Object.keys(initialVideoFormData)]),
              id: levelData.activity_id,
              activity_template_id: levelData.activity_template_id
            })
        }
      }

      return updateLevel({
        ...pick(levelData, [...Object.keys(initialBaseFormData), 'id']),
        activity_type: levelData.activity_type.toUpperCase(),
      })
    })
  )
;

export const removeLevel = createAsyncThunk(
  'levels/removeLevel',
  withErrorHandler((id) => deleteLevel(id))
);

const levelsSlice = createSlice({
  name: 'levels',
  initialState: {
    levels: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLevels.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllLevels.fulfilled, (state, action) => {
        state.levels = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addLevel.fulfilled, (state, action) => {
        state.levels.push(action.payload);
      })
      .addCase(updateLevelData.fulfilled, (state, action) => {
        const index = state.levels.findIndex(level => level.id === action.payload.id);
        if (index !== -1) {
          state.levels[index] = action.payload;
        }
      })
      .addCase(removeLevel.fulfilled, (state, action) => {
        state.levels = state.levels.filter(level => level.id !== action.payload);
      });
  },
});

export default levelsSlice.reducer;
