export function transformLevelData(data) {
  const { activity,  ...levelData } = data;

  return {
    ...(activity && {...activity[0]}),
    ...levelData,
  };
}

export function mergeAndFilterFormFieldsGroups(formData, ...fields_group) {
  return fields_group.flat().filter(field => !field.show || field.show(formData))
}
