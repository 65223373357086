import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDebugWindowVisible: false,
  isSettingsWindowVisible: false,
  isBodySecondVisible: false,
  isBodySecondMounted: false,
  activeComponent: 'BodyTS',
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDebugWindow: (state) => {
      state.isDebugWindowVisible = !state.isDebugWindowVisible;
    },
    toggleSettingsWindow: (state) => {
      state.isSettingsWindowVisible = !state.isSettingsWindowVisible;
    },
    setDebugWindowVisibility: (state, action) => {
      state.isDebugWindowVisible = action.payload;
    },
    setSettingsWindowVisibility: (state, action) => {
      state.isSettingsWindowVisible = action.payload;
    },
    setBodySecondVisibility: (state, action) => {
      state.isBodySecondVisible = action.payload;
    },
    setBodySecondMounted: (state, action) => {
      state.isBodySecondMounted = action.payload;
    },
    setActiveComponent: (state, action) => {
      state.activeComponent = action.payload;
    },
  },
});

export const {
  toggleDebugWindow,
  toggleSettingsWindow,
  setDebugWindowVisibility,
  setSettingsWindowVisibility,
  setBodySecondVisibility,
  setBodySecondMounted,
  setActiveComponent,
} = uiSlice.actions;

export default uiSlice.reducer;