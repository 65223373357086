import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setSessionData, setProgramData, setUserData } from '../../../../store/sessionSlice';

export function useInitialization(sessionId, initialSessionData, initialProgramData, initialUserData) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const initializeChat = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            console.log('Initializing ClientPageTS with sessionId:', sessionId);

            if (!sessionId) {
                throw new Error('Session ID is not defined in URL');
            }

            if (!initialProgramData) {
                throw new Error('Program data is missing');
            }

            console.log('Program data:', initialProgramData);

            if (!initialProgramData.flow_url) {
                throw new Error('Flow URL is missing in program data');
            }

            dispatch(setSessionData(initialSessionData));
            dispatch(setProgramData(initialProgramData));
            dispatch(setUserData(initialUserData));

            setIsLoading(false);
        } catch (error) {
            console.error('Error initializing app:', error);
            setError('Failed to initialize the application. Please try again.');
            if (error.response && error.response.status === 401) {
                window.location.href = '/login';
            }
        }
    }, [sessionId, initialProgramData, initialSessionData, initialUserData, dispatch]);

    useEffect(() => {
        initializeChat();
    }, [initializeChat]);

    return { isLoading, error };
}