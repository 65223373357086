import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UnityProvider } from './services/unity';
import { ApolloProvider } from '@apollo/client';
import client from './apollo-client';

import * as api from './services/api';
import * as audio from './services/audio';
import * as chat from './services/chat';
import * as unity from './services/unity';
import * as utils from './services/utils';
import * as constants from './services/constants';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <UnityProvider>
                <App />
            </UnityProvider>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();