import { gql } from '@apollo/client';
import client from '../../apollo-client';

const GET_ALL_ACTIVITY_TEMPLATES = gql`
  query GetAllActivityTemplates {
    activity_templates {
      id
      title
      description
    }
  }
`;

const CREATE_ACTIVITY_TEMPLATE = gql`
  mutation CreateActivityTemplate($input: CreateActivityTemplateDto!) {
    createActivityTemplate(input: $input) {
      title
      description
    }
  }
`;

const GET_ACTIVITY_TEMPLATE_BY_ID = gql`
  query GetFlowById(
    $where: ActivityTemplateResolver_ActivityTemplates_FilterInputType!
  ) {
    activity_templates(where: $where) {
      id
      title
      description
    }
  }
`;

const UPDATE_ACTIVITY_TEMPLATE = gql`
  mutation UpdateFlow($input: UpdateActivityTemplateDto!) {
    updateActivityTemplate(input: $input) {
      id
      title
      description
    }
  }
`;

const DELETE_ACTIVITY_TEMPLATE = gql`
  mutation DeleteFlow($input: RemoveInputDto!) {
    removeActivityTemplate(input: $input) {
      id
    }
  }
`;

export const createActivityTemplate = async (activityTemplateData) => {
  const { data } = await client.mutate({
    mutation: CREATE_ACTIVITY_TEMPLATE,
    variables: { input: activityTemplateData },
  });
  return data.createActivityTemplate;
};

export const getActivityTemplateById = async (activityTemplateId) => {
  const { data } = await client.query({
    query: GET_ACTIVITY_TEMPLATE_BY_ID,
    variables: { where: { id: { eq: activityTemplateId } } },
  });
  return data.activity_templates;
};

export const deleteActivityTemplate = async (activityTemplateId) => {
  const { data } = await client.mutate({
    mutation: DELETE_ACTIVITY_TEMPLATE,
    variables: { input: { id: activityTemplateId } },
  });
  return data.removeActivityTemplate;
};

export const getAllActivityTemplates = async () => {
  const { data } = await client.query({
    query: GET_ALL_ACTIVITY_TEMPLATES,
    fetchPolicy: 'network-only',
  });
  return data.activity_templates;
};

export const updateActivityTemplates = async (
  activityTemplateId,
  activityTemplateData,
) => {
  const { data } = await client.mutate({
    mutation: UPDATE_ACTIVITY_TEMPLATE,
    variables: { input: { id: activityTemplateId, ...activityTemplateData } },
  });
  return data.updateActivityTemplate;
};
