import React, { useState, useEffect, useRef, useCallback } from "react";
import "./BodyTS.css";
import sendIcon from "../../../assets/images/TS/icon-send.svg";
import broomIcon from "../../../assets/images/TS/icon-broom.svg";
import WebcamUnityTS from "../WebcamUnityTS/WebcamUnityTS";
import TypingMessage from "../commonTS/TypingMessage";
import { clearFlowHistory } from '../../../services/api/chat';
import { useSelector } from 'react-redux';

export const BodyTS = ({
    title,
    messages,
    handleSendMessageWrapper,
    isTyping,
    onToggleAdditionalInfo,
    isBodySecondVisible,
    isHeadphoneActive
}) => {
    const [message, setMessage] = useState('');
    const containerRef = useRef(null);
    const lastMessageRef = useRef(null);
    const [isWebcamVisible, setIsWebcamVisible] = useState(false);
    const isTogglingRef = useRef(false);

    const programData = useSelector(state => state.session.programData);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsWebcamVisible(true);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const handleToggleAdditionalInfo = useCallback((data) => {
        console.log("BodyTS: handleToggleAdditionalInfo called", { isToggling: isTogglingRef.current, isBodySecondVisible });

        if (isTogglingRef.current || isBodySecondVisible) {
            console.log("BodyTS: Toggle operation in progress or BodySecond is visible, ignoring call");
            return;
        }

        isTogglingRef.current = true;
        console.log("BodyTS: Calling onToggleAdditionalInfo");
        onToggleAdditionalInfo(data);

        // Освобождаем блокировку через небольшую задержку
        setTimeout(() => {
            isTogglingRef.current = false;
            console.log("BodyTS: Toggle lock released");
        }, 100);
    }, [onToggleAdditionalInfo, isBodySecondVisible]);


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        if (message.trim()) {
            handleSendMessageWrapper(message);
            setMessage('');
        }
    };

    const handleClearHistory = () => {
        //setMessages([]);
        //clearFlowHistory(flowUrl);
        console.log('!!!NOT ACTIVE!!! History cleared for: ' + programData.flowUrl);
    };

    const renderMessages = () => {
        return messages.slice(0).reverse().map((msg, index) => {
            if (msg.type === 'system' || msg.isStartMessage) {
                return (
                    <div key={msg.id} className="chatbox-system-message system">
                        {msg.text}
                    </div>
                );
            } else if (msg.sender === 'Bot') {
                return (
                    <TypingMessage
                        key={msg.id}
                        message={msg}
                        isCompleted={msg.isCompleted}
                        isInterrupted={msg.isInterrupted}
                        onToggleAdditionalInfo={handleToggleAdditionalInfo}
                        isBodySecondVisible={isBodySecondVisible}
                    />
                );
            } else if (msg.sender === 'You') {
                return (
                    <div key={msg.id} className="chatbox-message user">
                        <div className="chatbox-message-content">{msg.text}</div>
                    </div>
                );
            }
            return null;
        });
    };

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <div className="chatbox-container" ref={containerRef}>
            <WebcamUnityTS
                containerRef={containerRef}
                isVisible={isWebcamVisible}
                isHeadphoneActive={isHeadphoneActive}
            />
            <div className="chatbox-inner">
                <div className="chatbox-header">
                    <div className="chatbox-header-title">{title}</div>
                    <div className="chatbox-header-icons">
                        <div className="chatbox-header-icon" onClick={handleClearHistory}>
                            <img src={broomIcon} alt="Broom Icon" className="chatbox-header-icon-img" />
                        </div>
                    </div>
                </div>
                <div className="chatbox-messages-container">
                    <div className="chatbox-gradient-overlay"></div>
                    <div className="chatbox-messages">
                        {renderMessages()}
                    </div>
                </div>
                <div className="chatbox-assistent">
                    <div className={`typing-indicator ${isTyping ? 'active' : ''}`}>
                        <div className="typing-dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="typing-text">Assistant</div>
                    </div>
                </div>
                <div className="chatbox-footer">
                    <input
                        type="text"
                        placeholder="Enter your message..."
                        className="chatbox-footer-input"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <div className="chatbox-footer-send" onClick={handleSendMessage}>
                        <img src={sendIcon} alt="Send Icon" className="chatbox-footer-send-icon" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(BodyTS);