import React from 'react';
import ReactFlow, { Background, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import './NodeWorkflow.css';
import { nodeTypes } from './nodes/nodeTypes';
import NodeButtons from './components/NodeButtons';
import { useNodes } from './hooks/useNodes';
import { useEdges } from './hooks/useEdges';

const NodeWorkflow = () => {
  const {
    nodes,
    onNodesChange,
    addNode,
    onNodeDataChange,
    onNodeButtonClick,
    onDeleteNode,
    loading,
    error,
    addUser,
    updateUserData,
    removeUser,
    addProgram,
    removeProgram,
    addSession,
    removeSession
  } = useNodes();
  const { edges, onEdgesChange, onConnect, onEdgeClick } = useEdges(nodes);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="program-creator">
      <NodeButtons addNode={addNode} />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeClick={onEdgeClick}
        nodeTypes={nodeTypes}
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default NodeWorkflow;