import { useState, useCallback } from 'react';

export const useMicrophoneState = () => {
    const [micActive, setMicActive] = useState(false);

    const activateMic = useCallback(() => setMicActive(true), []);
    const deactivateMic = useCallback(() => setMicActive(false), []);

    return {
        micActive,
        activateMic,
        deactivateMic
    };
};