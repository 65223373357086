import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles/TabbarTS.css";
import iconMicOff from "../../../assets/images/TS/icon-mic-off.svg";
import iconMicOn from "../../../assets/images/TS/icon-mic.svg";
import iconChevronUp from "../../../assets/images/TS/icon-chevron-up.svg";
import iconChevronDown from "../../../assets/images/TS/icon-chevron-down.svg";
import iconExitRoom from "../../../assets/images/TS/icon-exit-room.svg";
import iconSettings from "../../../assets/images/TS/icon-sliders-horisontal.svg";
import RecordingOptionsTS from '../commonTS/RecordingOptionsTS';
import { useMicrophoneState } from './hooks/useMicrophoneState';
import { useRecordingMode } from './hooks/useRecordingMode';
import { useRecordingActions } from './hooks/useRecordingActions';
import { useLanguageToggle } from './hooks/useLanguageToggle';
import { useVolumeIndicator } from './hooks/useVolumeIndicator';
import { debugService } from '../../../services/utils/debugService';
import { setSttInput } from '../../../store/sttSlice';

const TabbarTS = () => {
    const dispatch = useDispatch();
    const activeComponent = useSelector(state => state.ui.activeComponent);
    
    const [showOptions, setShowOptions] = useState(false);
    const [error, setError] = useState(null);
    const { micActive, activateMic, deactivateMic } = useMicrophoneState();
    const { recordingMode, setRecordingMode } = useRecordingMode();
    const { language, toggleLanguage, getDisplayLanguage } = useLanguageToggle();
    const volume = useVolumeIndicator(micActive);

    const onFinalTranscript = useCallback((text) => {
        console.log(`TabbarTS: Dispatching STT input: ${text}`);
        dispatch(setSttInput(text));
    }, [dispatch]);

    const { handleMicClick, handleMicPressStart, handleMicPressEnd, stopRecording, isListening } = useRecordingActions(
        recordingMode,
        activateMic,
        deactivateMic,
        onFinalTranscript,
        language
    );

    useEffect(() => {
        debugService.log(`Режим записи: ${recordingMode}, Язык: ${language}, Микрофон активен: ${micActive}, Слушает: ${isListening}`);
    }, [recordingMode, language, micActive, isListening]);

    const handleMicClickWithErrorHandling = async () => {
        try {
            await handleMicClick();
            setError(null);
        } catch (err) {
            debugService.log(`Ошибка при работе с микрофоном: ${err.message}`);
            setError("Проблема с доступом к микрофону. Пожалуйста, проверьте настройки браузера.");
        }
    };

    const handleRecordingModeChange = (newMode) => {
        setRecordingMode(newMode);
        if (newMode === 'hold' && isListening) {
            stopRecording();
        }
    };

    return (
        <div className="tabbar-container">
            <div className="tabbar-left">
                <div className="recording-mode">{recordingMode}</div>
                <button className="language-toggle" onClick={toggleLanguage}>
                    {getDisplayLanguage()}
                </button>
                {/*<div className="volume-indicator-container">*/}
                {/*    <div className="volume-indicator" style={{ width: `${volume}%` }}></div>*/}
                {/*</div>*/}
                {error && <div className="error-message">{error}</div>}
            </div>
            <div className="tabbar-center">
                <div className="mic-container">
                    <div className="mic">
                        <div
                            className={`mic-mic ${isListening ? 'active' : ''} ${recordingMode}`}
                            onClick={handleMicClickWithErrorHandling}
                            onMouseDown={handleMicPressStart}
                            onMouseUp={handleMicPressEnd}
                            onTouchStart={handleMicPressStart}
                            onTouchEnd={handleMicPressEnd}>
                            <img src={isListening ? iconMicOn : iconMicOff} alt="Mic Icon" className="tabbar-item-icon" />
                        </div>
                        <div className="mic-chevron" onClick={() => setShowOptions(!showOptions)}>
                            <img src={showOptions ? iconChevronDown : iconChevronUp} alt="Chevron Icon" className="tabbar-item-icon" />
                            <RecordingOptionsTS setRecordingMode={handleRecordingModeChange} closeOptions={() => setShowOptions(false)} show={showOptions} />
                        </div>
                    </div>
                </div>
                <div className="leave-container">
                    <img src={iconExitRoom} alt="Exit Room Icon" className="tabbar-item-icon" />
                </div>
            </div>
            <div className="tabbar-right">
                <div className="settings-container">
                    <img src={iconSettings} alt="Settings Icon" className="tabbar-item-icon" />
                </div>
            </div>
        </div>
    );
};

export default TabbarTS;