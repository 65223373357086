import { useState, useCallback } from 'react';
import { useUnity } from '../../../../services/unity';

export function useCharacterToggle() {
    const [currentCharacter, setCurrentCharacter] = useState('mike');
    const { UCONTEXT_sendMessageToUnity } = useUnity();

    const toggleCharacter = useCallback(() => {
        const newCharacter = currentCharacter === 'mike' ? 'anna' : 'mike';
        setCurrentCharacter(newCharacter);
        UCONTEXT_sendMessageToUnity('CharacterSelect', 'ChangeCharacter', newCharacter, 'main-chat');
        console.log(`Переключение на персонаж ${newCharacter}`);
    }, [currentCharacter, UCONTEXT_sendMessageToUnity]);

    return { currentCharacter, toggleCharacter };
}