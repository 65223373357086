import React from 'react';
import BaseNode from './BaseNode';

const SessionNode = ({ id, data }) => {
  return (
    <BaseNode
      id={id}
      data={{
        ...data,
        title: 'Session',
        inputs: [
          { id: 'userId', label: 'User ID', color: '#ff6b6b' },
          { id: 'programId', label: 'Program ID', color: '#4ecdc4' },
          { id: 'levelIndex', label: 'Level INDEX', color: '#45b7d1' },
        ],
        fields: [
          { id: 'createSession', type: 'button', label: 'Create Session' },
        ],
      }}
    />
  );
};

export default SessionNode;