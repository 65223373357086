import React, { useState, useEffect, useRef, useCallback } from 'react';
import BrainstormActivity from '../ActivitysTS/BrainstormActivity';

const TypingMessage = ({ message, isCompleted, isInterrupted, onToggleAdditionalInfo, isBodySecondVisible }) => {
    const [displayedContent, setDisplayedContent] = useState([]);
    const [isTyping, setIsTyping] = useState(true);
    const [isBrainstormActivityRendered, setIsBrainstormActivityRendered] = useState(false);
    const animationRef = useRef(null);
    const startTimeRef = useRef(null);

    useEffect(() => {
        if (!message || !message.text || !message.text.messages) {
            console.warn('TypingMessage: Received invalid message format', message);
            return;
        }

        if (isCompleted || isInterrupted) {
            setDisplayedContent(message.text.messages.map(processContentItem));
            setIsTyping(false);
            return;
        }

        startTimeRef.current = Date.now();
        const totalDuration = message.audioDuration || 5000;

        const contentItems = message.text.messages;
        const totalTextLength = contentItems.reduce((sum, item) =>
            sum + (item.type === 'text' ? item.content.length : 0), 0);

        let currentItemIndex = 0;
        let currentCharIndex = 0;

        const animateTyping = () => {
            const elapsedTime = Date.now() - startTimeRef.current;
            const overallProgress = Math.min(elapsedTime / totalDuration, 1);

            const newDisplayedContent = [];
            let accumulatedProgress = 0;

            for (let i = 0; i < contentItems.length; i++) {
                const item = contentItems[i];
                if (item.type === 'text') {
                    const itemProgressShare = item.content.length / totalTextLength;
                    const itemStartProgress = accumulatedProgress;
                    const itemEndProgress = itemStartProgress + itemProgressShare;

                    if (overallProgress > itemStartProgress) {
                        const itemProgress = Math.min((overallProgress - itemStartProgress) / itemProgressShare, 1);
                        const displayedLength = Math.floor(item.content.length * itemProgress);
                        newDisplayedContent.push({
                            type: 'text',
                            content: item.content.slice(0, displayedLength)
                        });

                        if (itemProgress === 1 && i < contentItems.length - 1) {
                            // Если этот текстовый элемент полностью отображен и он не последний,
                            // отображаем следующий нетекстовый элемент мгновенно
                            let nextIndex = i + 1;
                            while (nextIndex < contentItems.length && contentItems[nextIndex].type !== 'text') {
                                newDisplayedContent.push(processContentItem(contentItems[nextIndex]));
                                nextIndex++;
                            }
                        }
                    }

                    accumulatedProgress = itemEndProgress;
                }
            }

            setDisplayedContent(newDisplayedContent);

            if (overallProgress < 1) {
                animationRef.current = requestAnimationFrame(animateTyping);
            } else {
                setIsTyping(false);
                setDisplayedContent(contentItems.map(processContentItem));
            }
        };

        animateTyping();

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [message, isCompleted, isInterrupted]);

    useEffect(() => {
        if (isBrainstormActivityRendered || !displayedContent.length) return;

        const brainstormingItem = displayedContent.find(item => item.type === 'brainstorming');

        if (brainstormingItem) {
            const timer = setTimeout(() => {
                if (!isBrainstormActivityRendered) {
                    handleBrainstormingClick(brainstormingItem.content);
                    setIsBrainstormActivityRendered(true);
                }
            }, 1000); // Задержка в 1 секунду

            return () => clearTimeout(timer); // Очистка таймера при размонтировании компонента
        }
    }, [isBrainstormActivityRendered, displayedContent]);

    const processContentItem = (item) => {
        if (item.type === 'text') {
            return { type: 'text', content: item.content };
        } else if (item.type === 'brainstorming') {
            return { type: 'brainstorming', content: item.content };
        }
        return null;
    };

    const handleBrainstormingClick = useCallback((brainstormingData) => {
        console.log("TypingMessage: handleBrainstormingClick called");
        console.log("Brainstorming data in TypingMessage:", brainstormingData);
        if (typeof onToggleAdditionalInfo === 'function' && !isBodySecondVisible) {
            onToggleAdditionalInfo(brainstormingData);
        }
    }, [onToggleAdditionalInfo, isBodySecondVisible]);

    const renderFormattedText = (text) => {
        // Разбиваем текст на параграфы
        const paragraphs = text.split('\n\n');

        return paragraphs.map((paragraph, index) => {
            // Обрабатываем жирный текст и одиночные переносы строк
            const lines = paragraph.split('\n');

            return (
                <p key={index}>
                    {lines.map((line, lineIndex) => {
                        const parts = line.split(/(\*\*.*?\*\*)/g);

                        return (
                            <React.Fragment key={lineIndex}>
                                {parts.map((part, i) => {
                                    if (part.startsWith('**') && part.endsWith('**')) {
                                        return <strong key={i}>{part.slice(2, -2)}</strong>;
                                    }
                                    return part;
                                })}
                                {lineIndex < lines.length - 1 && <br />}
                            </React.Fragment>
                        );
                    })}
                </p>
            );
        });
    };

    return (
        <div className={`chatbox-message bot ${isTyping ? 'typing' : ''}`}>
            <div className="chatbox-message-content">
                {displayedContent.map((content, index) => {
                    if (content.type === 'text') {
                        return (
                            <div key={`text-${index}`} className="chatbox-message-text">
                                {renderFormattedText(content.content)}
                            </div>
                        );
                    } else if (content.type === 'brainstorming') {
                        return (
                            <BrainstormActivity
                                key={`brainstorming-${index}`}
                                data={content.content}
                                onClick={() => handleBrainstormingClick(content.content)}
                                isOpen={isBodySecondVisible}
                            />
                        );
                    }
                    return null;
                })}
                {isTyping && (
                    <div className="typing-indicator">
                        <div className="typing-dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(TypingMessage);