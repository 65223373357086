import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import UsersComponent from './components/UsersComponent';
import ProgramsComponent from './components/ProgramsComponent';
import LevelsComponent from './components/LevelsComponent';
import SessionsComponent from './components/SessionsComponent';
import MySessionsComponent from './components/MySessionsComponent';
import NodeWorkflow from './NodeEditor/NodeWorkflow';
import { LogOut } from 'lucide-react';
import './styles/Dashboard.css';
import useDashboardPageRefresh from './hooks/useDashboardPageRefresh';
import FlowsComponent from "./components/FlowsComponent";
import ActivityTemplatesComponent from "./components/ActivityTemplatesComponent";

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [activeCategory, setActiveCategory] = useState('mySessions');
    const [notification, setNotification] = useState(null);

    useDashboardPageRefresh();

    useEffect(() => {
        const handlePopState = () => {
            window.location.reload();
        };

        window.addEventListener('popstate', handlePopState);

        const getUserData = () => {
            const savedState = localStorage.getItem('dashboardState');
            if (savedState) {
                const parsedState = JSON.parse(savedState);
                localStorage.removeItem('dashboardState');
                return parsedState;
            }

            const token = localStorage.getItem('token');
            if (token) {
                const decoded = jwtDecode(token);
                return {
                    nickname: decoded.username,
                    userId: decoded.id,
                    role: decoded.role
                };
            }

            return null;
        };

        const data = getUserData() || location.state;
        if (data) {
            setUserData(data);
        } else {
            navigate('/login');
        }

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate, location.state]);

    useEffect(() => {
        if (userData) {
            console.log('User data in Dashboard:', userData);
        }
    }, [userData]);

    const renderActiveComponent = () => {
        if (!userData) return null;

        switch (activeCategory) {
            case 'users':
                return <UsersComponent setNotification={setNotification} />;
            case 'programs':
                return <ProgramsComponent setNotification={setNotification} />;
            case 'levels':
                return <LevelsComponent setNotification={setNotification} />;
            case 'sessions':
                return <SessionsComponent setNotification={setNotification} />;
            case 'flows':
                return <FlowsComponent setNotification={setNotification} />;
            case 'activity-templates':
              return <ActivityTemplatesComponent setNotification={setNotification} />;
            case 'NodeEditor':
                return <NodeWorkflow setNotification={setNotification} />;
            case 'mySessions':
            default:
                return <MySessionsComponent userId={userData.userId} userData={userData} setNotification={setNotification} />;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('dashboardState');
        navigate('/login');
    };

    if (!userData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="dashboard">
            <header className="dashboardHeader">
                <h1>{userData.role === 'admin' ? 'Admin Dashboard' : 'User Dashboard'}</h1>
                <div className="headerRight">
                    <p>Welcome, {userData.nickname}!</p>
                    <button onClick={handleLogout} className="logoutButton">
                        <LogOut size={24} />
                    </button>
                </div>
            </header>
            <div className="dashboardContent">
                <nav className="categoryNav">
                    <div className="categoryGroup">
                        <h3>User activity</h3>
                        <button
                            className={`mySessionsBtn ${activeCategory === 'mySessions' ? 'active' : ''}`}
                            onClick={() => setActiveCategory('mySessions')}
                        >
                            My sessions
                        </button>
                    </div>
                    {userData.role === 'admin' && (
                      <div className="categoryGroup">
                        <h3>Admin tools</h3>
                        <button
                          className={activeCategory === 'users' ? 'active' : ''}
                          onClick={() => setActiveCategory('users')}
                        >
                          Users
                        </button>
                        <button
                          className={activeCategory === 'sessions' ? 'active' : ''}
                          onClick={() => setActiveCategory('sessions')}
                        >
                          Sessions
                        </button>
                        <button
                          className={activeCategory === 'programs' ? 'active' : ''}
                          onClick={() => setActiveCategory('programs')}
                        >
                          Programs
                        </button>
                        <button
                          className={activeCategory === 'levels' ? 'active' : ''}
                          onClick={() => setActiveCategory('levels')}
                        >
                          Levels
                        </button>
                        <button
                          className={activeCategory === 'flows' ? 'active' : ''}
                          onClick={() => setActiveCategory('flows')}
                        >
                          Flows
                        </button>
                        <button
                          className={activeCategory === 'activity-templates' ? 'active' : ''}
                          onClick={() => setActiveCategory('activity-templates')}
                        >
                          Activity templates
                        </button>
                        {/*<button*/}
                        {/*    className={activeCategory === 'NodeEditor' ? 'active' : ''}*/}
                        {/*    onClick={() => setActiveCategory('NodeEditor')}*/}
                        {/*>*/}
                        {/*    Node Editor*/}
                        {/*</button>*/}
                      </div>
                    )}
                </nav>
              <main className="dashboardMain">
                {notification && (
                  <div className={`notification ${notification.type}`}>
                    {notification.message}
                  </div>
                    )}
                    <div className="componentWrapper">
                        {renderActiveComponent()}
                    </div>
                </main>
            </div>
        </div>    );
};

export default Dashboard;
