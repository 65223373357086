import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './sessionSlice';
import uiReducer from './uiSlice';
import sttReducer from './sttSlice';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    ui: uiReducer,
    stt: sttReducer,
  },
});