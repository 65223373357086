import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import './style/WebcamUnityTS.css';
import { useUnity } from '../../../services/unity';
import { useDraggable } from './hooks/useDraggable';
import { useResizeObserver } from './hooks/useResizeObserver';
import { useWebcamPosition } from './hooks/useWebcamPosition';
import HeadphoneIcon from './components/HeadphoneIcon';

const BaseWebcamUnityTS = React.memo(({
    containerRef,
    isVisible,
    isHeadphoneActive = false,
    webcamSize = { width: 180, height: 180 },
    margins = { top: 16, bottom: 130, left: 16, right: 16 },
    unityContainerId,
    onUnityLoad,
    children
}) => {
    const webcamRef = useRef(null);
    const [isUnityLoaded, setIsUnityLoaded] = useState(false);
    const { UCONTEXT_sendMessageToUnity, activityUnityLoaded } = useUnity();

    const { position, updatePosition, onDrag } = useWebcamPosition(containerRef, webcamSize, margins);
    const { dragging, startDrag } = useDraggable(onDrag);

    useResizeObserver(containerRef, updatePosition);

    const sendMessageToUnity = useCallback(() => {
        if (activityUnityLoaded && !isUnityLoaded) {
            console.log('Sending message to Unity...');
            UCONTEXT_sendMessageToUnity('CharacterSelect', 'ChangeCharacter', 'default', unityContainerId);
            console.log('Message sent to Unity');
            setIsUnityLoaded(true);
        }
    }, [UCONTEXT_sendMessageToUnity, activityUnityLoaded, isUnityLoaded, unityContainerId]);

    useEffect(() => {
        if (activityUnityLoaded) {
            sendMessageToUnity();
        }
    }, [activityUnityLoaded, sendMessageToUnity]);

    useEffect(() => {
        const unityContainer = document.getElementById(unityContainerId);
        if (unityContainer && webcamRef.current) {
            webcamRef.current.appendChild(unityContainer);
            unityContainer.style.display = 'block';
            unityContainer.style.pointerEvents = 'none';
        }
        return () => {
            if (unityContainer) {
                document.body.appendChild(unityContainer);
                unityContainer.style.display = 'none';
                unityContainer.style.pointerEvents = 'auto';
            }
        };
    }, [unityContainerId]);

    const containerStyle = useMemo(() => ({
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: `${webcamSize.width}px`,
        height: `${webcamSize.height}px`,
        position: 'absolute',
        cursor: 'move',
        transform: `scale(${isVisible ? 1 : 0})`,
        transition: dragging ? 'none' : 'transform 0.3s ease-out, left 0.3s ease-out, top 0.3s ease-out',
    }), [position, webcamSize, isVisible, dragging]);

    return (
        <div
            className={`webcam ${dragging ? 'dragging' : ''}`}
            ref={webcamRef}
            onMouseDown={startDrag}
            style={containerStyle}
        >
            {children}
            <HeadphoneIcon isActive={isHeadphoneActive} />
            {!isUnityLoaded && <div className="loading-overlay">Loading...</div>}
        </div>
    );
});

export default BaseWebcamUnityTS;