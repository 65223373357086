import React from 'react';
import './styles/CommonListLayout.css';

const CommonListLayout = ({ children, createNewButton }) => (
    <div className="commonListWrapper">
        <div className="commonList">
            {children}
        </div>
        {createNewButton}
    </div>
);

export default CommonListLayout;