import { gql } from '@apollo/client';
import client from '../../apollo-client'; // Путь к вашему Apollo Client

// Запрос для получения всех программ
const GET_ALL_PROGRAMS = gql`
  query GetAllPrograms {
    programs {
      id
      title
      program_flow_id
      user_advancement_evaluator_flow_id
      levels {
        id
        index
        title
        content
        description_short
        description_long
        activity_id
        activity_type
      }
    }
  }
`;

// Мутация для создания программы
const CREATE_PROGRAM = gql`
  mutation CreateProgram($input: CreateProgramDto!) {
    createPrograms(input: $input) {
      id
      title
      levels {
        id
        index
        title
        content
        description_short
        description_long
        activity_id
        activity_type
      }
    }
  }
`;

// Запрос для получения программы по ID
const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($where: ProgramsResolver_Program_FilterInputType!) {
    program(where: $where) {
      id
      title
    }
  }
`;

// Мутация для обновления программы
const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($input: UpdateProgramDto!) {
    updateProgram(input: $input) {
      id
      title
      levels {
        id
        index
        title
        content
        description_short
        description_long
        activity_id
        activity_type
      }
    }
  }
`;

// Мутация для удаления программы
const DELETE_PROGRAM = gql`
  mutation DeleteProgram($input: RemoveInputDto!) {
    removeProgram(input: $input) {
      id
    }
  }
`;

export const createProgram = async (programData) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_PROGRAM,
      variables: { input: programData },
    });
    console.log('Program created:', data.createPrograms);
    return data.createPrograms;
  } catch (error) {
    console.error('Error creating program:', error);
    throw error;
  }
};

export const getProgramById = async (programId) => {
  console.log('Fetching program data for ID:', programId);
  try {
    const { data } = await client.query({
      query: GET_PROGRAM_BY_ID,
      variables: { where: { id: { eq: programId } } },
    });
    console.log('Program data fetched:', data.program);
    return data.program;
  } catch (error) {
    console.error('Error fetching program data:', error);
    throw error;
  }
};

export const deleteProgram = async (programId) => {
  console.log(`Attempting to delete program with ID: ${programId}`);
  try {
    const { data } = await client.mutate({
      mutation: DELETE_PROGRAM,
      variables: { input: { id: programId } },
    });
    console.log(
      `Program with ID ${programId} successfully deleted:`,
      data.removeProgram,
    );
    return data.removeProgram;
  } catch (error) {
    console.error(`Error deleting program with ID ${programId}:`, error);
    if (error.graphQLErrors) {
      const errorCode = error.graphQLErrors[0]?.extensions?.code;
      if (errorCode === 'CONFLICT') {
        throw new Error('Cannot delete program with active sessions');
      } else if (errorCode === 'NOT_FOUND') {
        throw new Error('Program not found');
      }
    }
    throw new Error('An unexpected error occurred while deleting the program');
  }
};

export const getAllPrograms = async () => {
  try {
    const { data } = await client.query({
      query: GET_ALL_PROGRAMS,
      fetchPolicy: 'network-only',
    });
    console.log('All programs fetched:', data.programs);
    return data.programs;
  } catch (error) {
    console.error('Error fetching all programs:', error);
    throw error;
  }
};

export const updateProgram = async (programId, programData) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PROGRAM,
      variables: { input: { id: programId, ...programData } },
    });
    console.log('Program updated:', data.updateProgram);
    return data.updateProgram;
  } catch (error) {
    console.error('Error updating program:', error);
    throw error;
  }
};
