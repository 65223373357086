import { useState, useEffect, useCallback } from 'react';
import { debugService } from '../../../../services/utils/debugService';

export const useVolumeIndicator = (micActive) => {
    const [volume, setVolume] = useState(0);
    const [audioContext, setAudioContext] = useState(null);
    const [analyser, setAnalyser] = useState(null);
    const [microphone, setMicrophone] = useState(null);

    const createAudioContext = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const context = new (window.AudioContext || window.webkitAudioContext)();
            const source = context.createMediaStreamSource(stream);
            const analyserNode = context.createAnalyser();

            source.connect(analyserNode);
            analyserNode.fftSize = 256;

            setAudioContext(context);
            setAnalyser(analyserNode);
            setMicrophone(source);

            debugService.log('����� �������� ������ ��� ���������� ���������');
            return true;
        } catch (error) {
            debugService.log('������ ��� �������� ����� ���������: ' + error.message);
            return false;
        }
    }, []);

    const updateVolume = useCallback(() => {
        if (analyser) {
            const dataArray = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
            const normalizedVolume = Math.min(100, Math.round((average / 128) * 100));
            setVolume(normalizedVolume);
        }
    }, [analyser]);

    useEffect(() => {
        let animationFrameId;

        const initAudio = async () => {
            if (micActive && !audioContext) {
                const success = await createAudioContext();
                if (success) {
                    const updateVolumeLoop = () => {
                        updateVolume();
                        animationFrameId = requestAnimationFrame(updateVolumeLoop);
                    };
                    updateVolumeLoop();
                }
            }
        };

        initAudio();

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            if (audioContext) {
                audioContext.close();
            }
            if (microphone) {
                microphone.disconnect();
            }
            setAudioContext(null);
            setAnalyser(null);
            setMicrophone(null);
        };
    }, [micActive, audioContext, createAudioContext, updateVolume]);

    return volume;
};