import React from 'react';

const FormField = ({
  label,
  type,
  name,
  value,
  onChange,
  options,
  render,
  readOnly,
}) => {
  const renderComplexData = (data) => {
    if (typeof data === 'object' && data !== null) {
      return (
        <div className='complexData'>
          {Object.entries(data).map(([key, val]) => (
            <div key={key} className='complexDataItem'>
              <strong>{key}:</strong> {renderComplexData(val)}
            </div>
          ))}
        </div>
      );
    }
    return String(data);
  };

  const inputField = () => {
    switch (type) {
      case 'select':
        return (
          <select
            name={name}
            value={value ?? ''}
            onChange={onChange}
            disabled={readOnly}
          >
            <option value=''>Select {label}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case 'display':
        return <div className='displayField'>{renderComplexData(value)}</div>;
      case 'textarea':
        return (
          <textarea
            name={name}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            rows='5'
          />
        );
      case 'custom':
        return render && render();
      default:
        return (
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
          />
        );
    }
  };

  return (
    <div className='formField'>
      <label htmlFor={name}>{label}</label>
      {inputField()}
    </div>
  );
};

export default FormField;
