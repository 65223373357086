let logs = [];
let listeners = [];

export const debugService = {
    log: (message) => {
        logs.push(message);
        listeners.forEach(listener => listener(logs));
    },

    getLogs: () => logs,

    clearLogs: () => {
        logs = [];
        listeners.forEach(listener => listener(logs));
    },

    subscribe: (listener) => {
        listeners.push(listener);
        return () => {
            listeners = listeners.filter(l => l !== listener);
        };
    }
};

if (typeof window !== 'undefined') {
    window.consoleWindow = {
        log: debugService.log
    };
}