import { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllPrograms,
  addProgram,
  updateProgramData,
  removeProgram,
} from '../../../../store/dashboard/programsSlice';
import { fetchAllLevels } from '../../../../store/dashboard/levelsSlice';
import CommonLayout from './common/CommonLayout';
import CommonListLayout from './common/CommonListLayout';
import ListItem from './common/ListItem';
import CreateNewButton from './common/CreateNewButton';
import EditForm from './common/EditForm';
import ProgramLevels from './common/ProgramLevels';
import { fetchAllFlows } from '../../../../store/dashboard/flowsSlice';
import { pick } from '../../../../utils/object.helper';

const programInitialState = Object.freeze({
  title: '',
  program_flow_id: '',
  user_advancement_evaluator_flow_id: '',
  levels: [],
});

const ProgramsComponent = () => {
  const dispatch = useDispatch();
  const {
    programs,
    loading: programsLoading,
    error: programsError,
  } = useSelector((state) => state.programs);
  const {
    levels,
    loading: levelsLoading,
    error: levelsError,
  } = useSelector((state) => state.levels);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [formData, setFormData] = useState({ ...programInitialState });

  const { flows } = useSelector((state) => state.flows);

  const refreshData = useCallback(() => {
    dispatch(fetchAllPrograms());
    dispatch(fetchAllLevels());
    dispatch(fetchAllFlows());
  }, [dispatch]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    if (selectedProgram) {
      setFormData({
        ...programInitialState,
        ...selectedProgram,
      });
    } else {
      setFormData({ ...programInitialState });
    }
  }, [selectedProgram]);

  useEffect(() => {
    const handler = ({ detail: result }) => {
      const items = Array.from(formData.levels);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const itemsWithUpdatedIndex = items.map((item, index) => ({
        ...item,
        index,
      }));

      handleInputChange({
        target: { name: 'levels', value: itemsWithUpdatedIndex },
      });
    };

    document.addEventListener('onDragEnd', handler);

    return () => {
      document.removeEventListener('onDragEnd', handler);
    };
  }, [formData.levels]);

  const handleSelectProgram = (program) => {
    setSelectedProgram(program);
    setIsCreating(false);
  };

  const handleCreateNewProgram = () => {
    setSelectedProgram(null);
    setIsCreating(true);
    setFormData({ ...programInitialState });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddLevel = (levelId) => {
    const levelToAdd = levels.find((level) => level.id === levelId);
    if (levelToAdd) {
      setFormData((prev) => ({
        ...prev,
        levels: [...prev.levels, levelToAdd],
      }));
    }
  };

  const handleRemoveLevel = (levelId) => {
    setFormData((prev) => ({
      ...prev,
      levels: prev.levels.filter((level) => level.id !== levelId),
    }));
  };

  const handleSaveProgram = async () => {
    try {
      if (isCreating) {
        await dispatch(
          addProgram({
            ...formData,
            levels: formData.levels.map((level) => level.id),
          }),
        ).unwrap();
      } else {
        await dispatch(
          updateProgramData({
            programId: selectedProgram.id,
            programData: {
              ...pick(formData, [
                'title',
                'program_flow_id',
                'user_advancement_evaluator_flow_id',
              ]),
              levels: formData.levels.map((level) =>
                pick(level, ['id', 'index']),
              ),
            },
          }),
        ).unwrap();
      }
      refreshData();
      setIsCreating(false);
    } catch (error) {
      console.error('Failed to save program:', error);
      alert(`Failed to save program: ${error.message}`);
    }
  };

  const handleDeleteProgram = async (programId) => {
    if (window.confirm('Are you sure you want to delete this program?')) {
      try {
        await dispatch(removeProgram(programId)).unwrap();
        setSelectedProgram(null);
        refreshData();
      } catch (error) {
        console.error('Failed to delete program:', error);
        alert(`Failed to delete program: ${error.message}`);
      }
    }
  };

  if (programsLoading || levelsLoading) {
    return <div>Loading...</div>;
  }

  if (programsError || levelsError) {
    return <div>Error: {programsError || levelsError}</div>;
  }

  const programList = (
    <CommonListLayout
      createNewButton={
        <CreateNewButton
          onClick={handleCreateNewProgram}
          label='+ Create New Program'
        />
      }
    >
      {programs.map((program) => (
        <ListItem
          key={program.id}
          title={program.title}
          descriptions={`Levels: ${program.levels.length}`}
          onDelete={() => handleDeleteProgram(program.id)}
          onClick={() => handleSelectProgram(program)}
        />
      ))}
    </CommonListLayout>
  );

  const programForm =
    selectedProgram || isCreating ? (
      <EditForm
        title={isCreating ? 'Create New Program' : 'Program Details'}
        fields={[
          {
            label: 'Title',
            type: 'text',
            name: 'title',
            value: formData.title,
            onChange: handleInputChange,
          },
          {
            label: 'User advancement evaluator flow',
            type: 'select',
            name: 'user_advancement_evaluator_flow_id',
            value: formData.user_advancement_evaluator_flow_id,
            onChange: handleInputChange,
            options: flows.map((flow) => ({
              value: flow.id,
              label: flow.title,
            })),
          },
          {
            label: 'Flow',
            type: 'select',
            name: 'program_flow_id',
            value: formData.program_flow_id,
            onChange: handleInputChange,
            options: flows.map((flow) => ({
              value: flow.id,
              label: flow.title,
            })),
          },
          {
            label: 'Levels',
            type: 'custom',
            render: () => (
              <div>
                <select onChange={(e) => handleAddLevel(e.target.value)}>
                  <option value=''>Select a level to add</option>
                  {levels.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.title}
                    </option>
                  ))}
                </select>
                <ProgramLevels
                  levels={formData.levels}
                  onRemoveLevel={handleRemoveLevel}
                />
              </div>
            ),
          },
        ]}
        onSave={handleSaveProgram}
        onCancel={() => {
          setSelectedProgram(null);
          setIsCreating(false);
        }}
        onDelete={
          !isCreating
            ? () => handleDeleteProgram(selectedProgram.id)
            : undefined
        }
      />
    ) : (
      <div className='noProgramSelected'>
        Select a program or create a new one
      </div>
    );

  return (
    <CommonLayout
      title='Programs'
      leftPanel={programList}
      rightPanel={programForm}
    />
  );
};

export default ProgramsComponent;
