import { gql } from '@apollo/client';
import client from '../../apollo-client';

const GET_ALL_LEVELS = gql`
  query GetAllLevels {
    levels {
      id
      index
      title
      content
      description_short
      description_long
      program_id
      activity_id
      activity_type
      activity {
        ... on BrainstormActivityObject {
          id
          assistant_flow_id
          idea_extractor_flow_id
          activity_template_id
        }
        ... on DiscussionActivityObject {
          id
          activity_template_id
        }
        ... on RoleplayActivityObject {
          id
          legend
          partner_legend
          partner_flow_id
          observer_list
          observer_flow_id
          activity_template_id
        }
        ... on TheoryActivityObject {
          id
          activity_template_id
        }
        ... on VideoActivityObject {
          id
          url
          assistant_flow_id
          activity_template_id
        }
      }
    }
  }
`;

const CREATE_LEVEL = gql`
  mutation CreateLevel($input: CreateLevelDto!) {
    createLevel(input: $input) {
      id
      index
      title
      content
      description_short
      description_long
      program_id
      activity_id
      activity_type
    }
  }
`;

const UPDATE_LEVEL = gql`
  mutation UpdateLevel($input: UpdateLevelDto!) {
    updateLevel(input: $input) {
      id
      index
      title
      content
      description_short
      description_long
      program_id
      activity_id
      activity_type
    }
  }
`;

const DELETE_LEVEL = gql`
  mutation DeleteLevel($id: String!) {
    removeLevel(id: $id) {
      id
    }
  }
`;

export const getAllLevels = async () => {
    try {
        const { data } = await client.query({
            query: GET_ALL_LEVELS,
            fetchPolicy: 'no-cache'
        });
        console.log("All levels fetched:", data.levels);
        return data.levels;
    } catch (error) {
        console.error("Error fetching all levels:", error);
        throw error;
    }
};

export const createLevel = async (levelData) => {
    try {
        const { data } = await client.mutate({
            mutation: CREATE_LEVEL,
            variables: { input: levelData }
        });
        console.log("Level created:", data.createLevel);
        return data.createLevel;
    } catch (error) {
        console.error("Error creating level:", error);
        throw error;
    }
};

export const updateLevel = async (levelData) => {
    try {
        const { data } = await client.mutate({
            mutation: UPDATE_LEVEL,
            variables: { input: levelData }
        });
        console.log("Level updated:", data.updateLevel);
        return data.updateLevel;
    } catch (error) {
        console.error("Error updating level:", error);
        throw error;
    }
};

export const deleteLevel = async (levelId) => {
    try {
        const { data } = await client.mutate({
            mutation: DELETE_LEVEL,
            variables: { id: levelId }
        });
        console.log("Level deleted:", data.removeLevel);
        return data.removeLevel;
    } catch (error) {
        console.error("Error deleting level:", error);
        throw error;
    }
};
