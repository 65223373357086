import { useEffect } from 'react';

export const useResizeObserver = (ref, callback) => {
    useEffect(() => {
        if (!ref.current) return;

        const resizeObserver = new ResizeObserver(() => {
            callback();
        });

        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref, callback]);
};