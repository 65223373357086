import React from 'react';
import BaseNode from './BaseNode';

const ProgramNode = ({ data }) => {
  return (
    <BaseNode
      data={{
        ...data,
        title: 'Program',
        fields: [
          {
            id: 'selectProgram',
            type: 'select',
            label: 'Select Program',
            options: data.programs.map(program => ({ value: program.id, label: program.title })),
            value: data.selectedProgram,
          },
          { id: 'title', type: 'input', label: 'Title', value: data.title },
          { id: 'urls', type: 'input', label: 'URLs', value: data.urls },
          { id: 'createProgram', type: 'button', label: 'Create Program' },
        ],
      }}
    />
  );
};

export default ProgramNode;