import React, { useState, useEffect } from 'react';
import './style/WebcamUnityTS.css';
import { useUnity } from '../../../services/unity';
import BaseWebcamUnityTS from './BaseWebcamUnityTS';

const ActivityWebcamUnityTS = React.memo(({ containerRef, isHeadphoneActive = false }) => {
    const [isVisible, setIsVisible] = useState(false);
    const { UCONTEXT_sendMessageToUnity, activityUnityLoaded } = useUnity();

    useEffect(() => {
        if (activityUnityLoaded) {
            console.log('Sending message to Unity...');
            UCONTEXT_sendMessageToUnity('CharacterSelect', 'ChangeCharacter', 'stive', 'activity');
            console.log('Message sent to Unity');
            setTimeout(() => setIsVisible(true), 100);
        }
    }, [activityUnityLoaded, UCONTEXT_sendMessageToUnity]);

    return (
        <BaseWebcamUnityTS
            containerRef={containerRef}
            isVisible={isVisible}
            isHeadphoneActive={isHeadphoneActive}
            unityContainerId="unity-container-activity"
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                }}
            />
        </BaseWebcamUnityTS>
    );
});

export default ActivityWebcamUnityTS;