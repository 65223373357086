import React from 'react';
import './styles/SessionButton.css';

const SessionButton = ({ session, program, userData, onEnter, onDelete }) => {
    const handleEnter = () => {
        onEnter(session, program, userData);
    };

    return (
        <div className="sessionCard">
            <h3>{program?.title || 'Unknown Program'}</h3>
            <p className="sessionId">ID: {session.id}</p>
            <div className="sessionActions">
                <button className="enterButton" onClick={handleEnter}>
                    Enter
                </button>
            </div>
        </div>
    );
};

export default SessionButton;