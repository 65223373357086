import React from 'react';
import './styles/CommonLayout.css';

const CommonLayout = ({ title, leftPanel, rightPanel }) => (
    <div className="commonLayout">
        <div className="leftPanel">
            <h2 className="panelTitle">{title}</h2>
            <div className="panelContent">
                {leftPanel}
            </div>
        </div>
        <div className="rightPanel">
            <div className="panelContent">
                {rightPanel}
            </div>
        </div>
    </div>
);

export default CommonLayout;