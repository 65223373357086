import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchAllData } from '../../../../store/dashboard/dashboardDataSlice';
import { fetchAllUsers, addUser, updateUserData, removeUser } from '../../../../store/dashboard/usersSlice';
import { fetchAllPrograms, addProgram, updateProgramData, removeProgram } from '../../../../store/dashboard/programsSlice';
import { fetchAllSessions, addSession, removeSession } from '../../../../store/dashboard/sessionsSlice';

export const useDashboardData = (userId) => {
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state.dashboardData);
    const users = useSelector((state) => state.users);
    const programs = useSelector((state) => state.programs);
    const sessions = useSelector((state) => state.sessions);

    const userSessions = sessions.sessions.filter(session => session.user_id === userId);

    const refreshData = useCallback(() => {
        console.log('Refreshing all data...');
        return Promise.all([
            dispatch(fetchAllData()),
            dispatch(fetchAllUsers()),
            dispatch(fetchAllPrograms()),
            dispatch(fetchAllSessions())
        ]);
    }, [dispatch]);

    return {
        userSessions,
        programs: programs.programs,
        loading: dashboardData.loading || users.loading || programs.loading || sessions.loading,
        error: dashboardData.error || users.error || programs.error || sessions.error,
        refreshData,
        addUser: (userData) => dispatch(addUser(userData)),
        updateUserData: (userId, userData) => dispatch(updateUserData({ userId, userData })),
        removeUser: (userId) => dispatch(removeUser(userId)),
        addProgram: (programData) => dispatch(addProgram(programData)),
        updateProgramData: (programId, programData) => dispatch(updateProgramData({ programId, programData })),
        removeProgram: (programId) => dispatch(removeProgram(programId)),
        addSession: (userId, programId) => dispatch(addSession({ userId, programId })),
        removeSession: (sessionId) => dispatch(removeSession(sessionId))
    };
};