import React from "react";
import { LogOut } from 'lucide-react';
import "./HeaderTS.css";
import { useSelector, useDispatch } from 'react-redux';
import { useExitHandling } from './hooks/useExitHandling';
import { useCharacterToggle } from './hooks/useCharacterToggle';
import { toggleDebugWindow, toggleSettingsWindow } from '../../../store/uiSlice';

export const HeaderTS = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.session.userData);
    const programData = useSelector(state => state.session.programData);
    const { handleExitClick } = useExitHandling();
    const { isSecondCharacter, toggleCharacter } = useCharacterToggle();

    const onDebugClick = () => dispatch(toggleDebugWindow());
    const onSettingsClick = () => dispatch(toggleSettingsWindow());

    return (
        <div className="header-container">
            <div className="header-inner">
                <div className="header-box">
                    <div className="header-text-container">
                        <div className="header-icon-container">
                            <div className="header-icon"></div>
                        </div>
                        <div>
                            <span className="header-title">{programData.title}</span>
                        </div>
                    </div>
                    <div className="header-arrow-container">
                        <div className="header-arrow"></div>
                    </div>
                </div>
            </div>
            <div className="header-buttons">
                {userData && userData.role === 'admin' && (
                    <>
                        <button className="header-button" onClick={toggleCharacter}>
                            CHARACTER
                        </button>
                        <button className="header-button" onClick={onSettingsClick}>
                            SETTINGS
                        </button>
                        <button className="header-button" onClick={onDebugClick}>
                            DEBUG
                        </button>
                    </>
                )}
                <button className="header-button exit-button" onClick={handleExitClick} title="Exit to Dashboard">
                    <LogOut size={24} strokeWidth={2} />
                </button>
            </div>
        </div>
    );
};

export default HeaderTS;