import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllLevels,
  addLevel,
  updateLevelData,
  removeLevel,
} from '../../../../store/dashboard/levelsSlice';
import { fetchAllPrograms } from '../../../../store/dashboard/programsSlice';
import CommonLayout from './common/CommonLayout';
import CommonListLayout from './common/CommonListLayout';
import ListItem from './common/ListItem';
import CreateNewButton from './common/CreateNewButton';
import EditForm from './common/EditForm';
import {
  mergeAndFilterFormFieldsGroups,
  transformLevelData,
} from '../../../../utils/form.helper';
import { fetchAllFlows } from '../../../../store/dashboard/flowsSlice';
import { fetchAllActivityTemplates } from '../../../../store/dashboard/activityTemplatesSlice';

export const EActivityType = Object.freeze({
  BRAINSTORM: 'brainstorm',
  DISCUSSION: 'discussion',
  ROLEPLAY: 'roleplay',
  THEORY: 'theory',
  VIDEO: 'video',
});

export const initialBaseFormData = Object.freeze({
  index: '',
  title: '',
  content: '',
  description_short: '',
  description_long: '',
  program_id: '',
  activity_id: '',
  activity_type: '',
});

export const initialBrainstormFormData = Object.freeze({
  assistant_flow_id: 0,
  idea_extractor_flow_id: 0,
});

export const initialRoleplayFormData = Object.freeze({
  legend: '',
  partner_legend: '',
  partner_flow_id: '',
  observer_list: '',
  observer_flow_id: '',
});

export const initialVideoFormData = Object.freeze({
  url: '',
  assistant_flow_id: '',
});

export const initialActivityTemplateFormData = Object.freeze({
  activity_template_id: '',
});

const initialLevelFormData = Object.freeze({
  ...initialBaseFormData,
  ...initialBrainstormFormData,
  ...initialRoleplayFormData,
  ...initialVideoFormData,
  ...initialActivityTemplateFormData,
});

const LevelsComponent = () => {
  const dispatch = useDispatch();
  const { levels, loading, error } = useSelector((state) => state.levels);
  const { programs } = useSelector((state) => state.programs);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [formData, setFormData] = useState({ ...initialLevelFormData });

  const { flows } = useSelector((state) => state.flows);
  const { activityTemplates } = useSelector((state) => state.activityTemplates);

  const refreshData = useCallback(() => {
    dispatch(fetchAllLevels());
    dispatch(fetchAllPrograms());
    dispatch(fetchAllFlows());
    dispatch(fetchAllActivityTemplates());
  }, [dispatch]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const handleSelectLevel = (level) => {
    setSelectedLevel(level);
    setIsCreating(false);
  };

  const handleCreateNewLevel = () => {
    setSelectedLevel(null);
    setIsCreating(true);
    setFormData({ ...initialBaseFormData });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const brainstorm_fields = [
    {
      label: 'Assistant flow',
      type: 'select',
      name: 'assistant_flow_id',
      value: formData.assistant_flow_id,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.BRAINSTORM,
      options: flows.map((flow) => ({ value: flow.id, label: flow.title })),
    },
    {
      label: 'Idea extractor flow',
      type: 'select',
      name: 'idea_extractor_flow_id',
      value: formData.idea_extractor_flow_id,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.BRAINSTORM,
      options: flows.map((flow) => ({ value: flow.id, label: flow.title })),
    },
  ];

  const rolegame_fields = [
    {
      label: 'Legend',
      type: 'string',
      name: 'legend',
      value: formData.legend,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.ROLEPLAY,
    },
    {
      label: 'Partner legend',
      type: 'string',
      name: 'partner_legend',
      value: formData.partner_legend,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.ROLEPLAY,
    },
    {
      label: 'Partner flow',
      type: 'select',
      name: 'partner_flow_id',
      value: formData.partner_flow_id,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.ROLEPLAY,
      options: flows.map((flow) => ({ value: flow.id, label: flow.title })),
    },
    {
      label: 'observer_list',
      type: 'string',
      name: 'observer_list',
      value: formData.observer_list,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.ROLEPLAY,
    },
    {
      label: 'Observer flow',
      type: 'select',
      name: 'observer_flow_id',
      value: formData.observer_flow_id,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.ROLEPLAY,
      options: flows.map((flow) => ({ value: flow.id, label: flow.title })),
    },
  ];

  const video_fields = [
    {
      label: 'url',
      type: 'string',
      name: 'url',
      value: formData.url,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.VIDEO,
    },
    {
      label: 'Assistant flow',
      type: 'select',
      name: 'assistant_flow_id',
      value: formData.assistant_flow_id,
      onChange: handleInputChange,
      show: (formData) => formData.activity_type === EActivityType.VIDEO,
      options: flows.map((flow) => ({ value: flow.id, label: flow.title })),
    },
  ];

  const level_fields = [
    {
      label: 'Index',
      type: 'number',
      name: 'index',
      value: formData.index,
      onChange: handleInputChange,
    },
    {
      label: 'Title',
      type: 'text',
      name: 'title',
      value: formData.title,
      onChange: handleInputChange,
    },
    {
      label: 'Content',
      type: 'text',
      name: 'content',
      value: formData.content,
      onChange: handleInputChange,
    },
    {
      label: 'Short Description',
      type: 'textarea',
      name: 'description_short',
      value: formData.description_short,
      onChange: handleInputChange,
    },
    {
      label: 'Long Description',
      type: 'textarea',
      name: 'description_long',
      value: formData.description_long,
      onChange: handleInputChange,
    },
    {
      label: 'Program',
      type: 'select',
      name: 'program_id',
      value: formData.program_id,
      onChange: handleInputChange,
      options:
        programs &&
        programs.map((program) => ({
          value: program.id,
          label: program.title,
        })),
    },
    {
      label: 'Activity Type',
      type: 'select',
      name: 'activity_type',
      value: formData.activity_type,
      onChange: handleInputChange,
      options: [
        { value: EActivityType.THEORY, label: 'Theory' },
        { value: EActivityType.BRAINSTORM, label: 'Brainstorm' },
        { value: EActivityType.ROLEPLAY, label: 'Roleplay' },
        { value: EActivityType.DISCUSSION, label: 'Discussion' },
        { value: EActivityType.VIDEO, label: 'Video' },
      ],
    },
  ];

  const activity_templates_fields = [
    {
      label: 'Activity template',
      type: 'select',
      name: 'activity_template_id',
      value: formData.activity_template_id ?? '',
      onChange: handleInputChange,
      show: (formData) => {
        console.log('data', formData);
        return !!formData.activity_type;
      },
      options: activityTemplates.map((activityTemplate) => ({
        value: activityTemplate.id,
        label: activityTemplate.title,
      })),
    },
  ];

  useEffect(() => {
    if (selectedLevel) {
      const data = transformLevelData(selectedLevel);
      setFormData(data);
    } else {
      setFormData({ ...initialLevelFormData });
    }
  }, [selectedLevel]);

  const handleSaveLevel = async () => {
    try {
      const formattedData = { ...formData, index: Number(formData.index) };
      if (isCreating) {
        await dispatch(addLevel(formattedData)).unwrap();
      } else {
        await dispatch(
          updateLevelData({ id: selectedLevel.id, ...formattedData }),
        ).unwrap();
      }
      refreshData();
      setIsCreating(false);
    } catch (error) {
      console.error('Failed to save level:', error);
      alert(`Failed to save level: ${error.message}`);
    }
  };

  const handleDeleteLevel = async (levelId) => {
    if (window.confirm('Are you sure you want to delete this level?')) {
      try {
        await dispatch(removeLevel(levelId)).unwrap();
        setSelectedLevel(null);
        refreshData();
      } catch (error) {
        console.error('Failed to delete level:', error);
        alert(`Failed to delete level: ${error.message}`);
      }
    }
  };

  if (loading) {
    return <div>Loading levels...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const levelList = (
    <CommonListLayout
      createNewButton={
        <CreateNewButton
          onClick={handleCreateNewLevel}
          label='+ Create New Level'
        />
      }
    >
      {levels &&
        levels.map((level) => (
          <ListItem
            key={level.id}
            title={level.title}
            descriptions={[`Index: ${level.index}`, level.description_short]}
            onDelete={() => handleDeleteLevel(level.id)}
            onClick={() => handleSelectLevel(level)}
          />
        ))}
    </CommonListLayout>
  );

  const levelForm =
    selectedLevel || isCreating ? (
      <EditForm
        title={isCreating ? 'Create New Level' : 'Level Details'}
        fields={mergeAndFilterFormFieldsGroups(
          formData,
          level_fields,
          brainstorm_fields,
          rolegame_fields,
          video_fields,
          activity_templates_fields,
        )}
        onSave={handleSaveLevel}
        onCancel={() => {
          setSelectedLevel(null);
          setIsCreating(false);
        }}
        onDelete={
          !isCreating ? () => handleDeleteLevel(selectedLevel.id) : undefined
        }
        readOnly={false}
      />
    ) : (
      <div className='noLevelSelected'>Select a level or create a new one</div>
    );

  return (
    <CommonLayout title='Levels' leftPanel={levelList} rightPanel={levelForm} />
  );
};

export default LevelsComponent;
